<template>
  <v-container class="help">
    <v-dialog v-model="help_dialog" scrollable :max-width="width ? width : 500">
      <template #activator="{ props }">
        <v-btn class="uwill-primary-button" icon size="x-small" v-bind="props">
          <v-icon size="x-small" icon="mdi:mdi-help" />
        </v-btn>
      </template>
      <v-card>
        <v-card-title v-if="title">
          <v-icon
            size="medium"
            class="mr-2 uwill-primary"
            icon="mdi:mdi-help-circle"
          />
          {{ title }}
        </v-card-title>
        <v-divider v-if="title" class="mt-0"></v-divider>
        <v-card-text
          style="height:100px;"
          :style="height ? 'height:' + height + 'px;' : ''"
        >
          <slot>Default help text</slot>
        </v-card-text>
        <v-divider class="mb-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="help_dialog = false">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: "Help",
  props: {
    text: String,
    title: String,
    height: Number,
    width: Number
  },
  data: () => ({
    help_dialog: false
  })
};
</script>
