<template>
  <v-dialog
    :model-value="modelValue"
    width="600"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <v-card
      role="alertdialog"
      class="terms-container"
      style="border-radius:30px; padding:20px"
    >
      <v-card flat class="mx-auto mb-4">
        <v-card-title>
          <b>Appointment Restriction Terms</b>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <div v-if="student && !supplemental">
                <p>
                  To ensure effective care, scheduled therapy sessions are
                  limited to two per week.
                  <b>The standard cadence is one session per week</b>, with a
                  supplemental session available based on clinical need.
                </p>
                <p>
                  This policy is based on clinical best practices, which
                  emphasize the importance of time between sessions for
                  insights, applying strategies, and fostering meaningful
                  progress. Research shows that meeting more frequently can slow
                  progress rather than enhance it. This approach helps maintain
                  the integrity of the therapeutic process and supports
                  long-term well-being.
                </p>
                <p>
                  Additional support options, such as group therapy or crisis
                  resources, may also be available – please reference your Uwill
                  dashboard and counseling center for more information.
                </p>
              </div>
              <div v-if="student && supplemental">
                <p>
                  To ensure effective care,
                  <b>scheduled therapy sessions are limited to one per week.</b>
                  If you believe you need a supplemental session, please discuss
                  this with your therapist. In collaboration with the Uwill
                  team, your therapist will determine if it is clinically
                  appropriate. Requests for exceptions will be considered on a
                  case-by-case basis.
                </p>
                <p>
                  This policy is based on clinical best practices, which
                  emphasize the importance of time between sessions for
                  processing insights, applying strategies, and fostering
                  meaningful progress. Research shows that meeting more
                  frequently can slow progress rather than enhance it. This
                  approach helps maintain the integrity of the therapeutic
                  process and supports long-term well-being.
                </p>
                <p>
                  Additional support options, such as group therapy or crisis
                  resources, may also be available – please reference your Uwill
                  dashboard and counseling center for more information.
                </p>
              </div>
              <div v-if="therapist && !supplemental">
                <p>
                  To ensure effective care, therapy sessions are limited to two
                  per week. <b>The standard cadence is one session per week</b>,
                  with a supplemental session available based on clinical need.
                  This policy is based on clinical best practices, which
                  emphasize the importance of time between sessions for
                  processing insights, applying strategies, and fostering
                  meaningful progress.
                </p>
                <p>
                  Research shows that meeting more frequently can slow progress
                  rather than enhance it. Therapy is most effective when clients
                  have time between sessions to process insights, apply new
                  strategies, and reflect on their growth. This balance helps
                  create meaningful, lasting change.
                </p>
                <p>
                  If you feel a client needs more support than two sessions per
                  week, please email
                  <a href="mailto:clinical@uwill.com">clinical@uwill.com</a>
                  to discuss and determine the best next steps for the client’s
                  care.
                </p>
              </div>
              <div v-if="therapist && supplemental">
                <p>
                  To ensure effective care,
                  <b>therapy sessions are limited to one per week.</b> A
                  supplemental session may be considered at your discretion,
                  client’s clinical needs, for a limited amount of time. This
                  policy is based on clinical best practices, which emphasize
                  the importance of time between sessions for processing
                  insights, applying strategies, and fostering meaningful
                  progress.
                </p>
                <p>
                  Research shows that meeting more frequently can slow progress
                  rather than enhance it. Therapy is most effective when clients
                  have time between sessions to process insights, apply new
                  strategies, and reflect on their growth. This balance helps
                  create meaningful, lasting change.
                </p>
                <p>
                  If you feel a client needs continued two sessions per week or
                  additional support, please email
                  <a href="mailto:clinical@uwill.com">clinical@uwill.com</a> to
                  discuss and determine the best next steps for the client’s
                  care.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card-actions class="close-btn-container">
        <v-spacer></v-spacer>
        <v-btn class="terms-close-btn" @click="closeDialog">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RestrictionTerms",
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    student: {
      type: Boolean,
      default: null
    },
    therapist: {
      type: Boolean,
      default: null
    },
    supplemental: {
      type: Boolean,
      default: null
    }
  },
  emits: ["update:modelValue"],
  methods: {
    closeDialog() {
      this.$emit("update:modelValue", false);
    }
  }
};
</script>
<style>
.close-btn-container {
  display: grid;
  justify-content: center !important;
}
.terms-close-btn {
  width: 156px;
  height: 40px;
  border: 1px solid #6667AB !important;
  border-radius: 6px;
  text-transform: none;
}
</style>
