<template>
  <div>
    <v-dialog
      v-model="promo_dialog"
      width="500"
      aria-label="Redeem a Promo Code"
    >
      <v-card id="promo_model">
        <v-card-title class="text-h5">
          <h4>Redeem a Promo Code</h4>
        </v-card-title>
        <v-card-text>
          Enter the promo code below and we will check to make sure it is valid.
          Once it's validated, you will receive its benefit.
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="promo_code"
            label="Enter your promo code here"
            prepend-icon="mdi:mdi-alphabetical"
            type="text"
            hint="The code should be at least 6 characters long"
            persistent-hint
          />
          <div
            v-if="promo_code_error"
            class="text-center mt-4 uwill-error"
            role="alert"
            aria-labelledby="promo_code_error"
          >
            {{ promo_code_error }}
          </div>
        </v-card-text>
        <v-card-text>
          <div
            class="g-recaptcha mb-3"
            data-sitekey="6LeCeDEpAAAAAL4Ws3Mgkf9cT2jwhj18c2l9H-g6"
            :data-action="'REDEEM_CODE'"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <a
            tabindex="0"
            href="javascript: void(0)"
            class="mr-4"
            @click="promo_dialog = false"
          >
            Cancel
          </a>
          <v-btn
            class="uwill-primary-button padded"
            role="button"
            :disabled="promo_code.length < 6"
            @click="validatePromoCode()"
          >
            Check my code
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PromoCode",
  emits: ["close-menu"],
  data: () => ({
    promo_code_error: null,
    promo_dialog: false,
    promo_code: ""
  }),
  watch: {
    promo_dialog: function(val) {
      if (val) {
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
          "src",
          "https://www.google.com/recaptcha/enterprise.js"
        );
        recaptchaScript.setAttribute("async", "true");
        recaptchaScript.setAttribute("defer", "true");
        document.head.appendChild(recaptchaScript);
      }
    }
  },
  methods: {
    recaptchaComplete: function() {
      return window.grecaptcha.enterprise.getResponse().length > 0;
    },
    openDialog: function() {
      this.promo_dialog = true;
    },
    validatePromoCode() {
      if (this.recaptchaComplete() && this.promo_code.length >= 6) {
        axios({
          method: "get",
          url: `/promo_codes?code=${this.promo_code}`
        }).then(resp => {
          if (resp.data.error) {
            this.promo_code_error = resp.data.error;
          } else {
            this.$store.dispatch("get_user");
            this.promo_dialog = false;
            this.promo_code = "";
            this.promo_code_error = null;
            window.Bus.$emit("flash-message", {
              text: resp.data.message
            });
          }
        });
      } else if (!this.recaptchaComplete() && this.promo_code.length >= 6) {
        this.promo_code_error = "Please complete captcha verification";
      } else {
        this.invitation_code_error = "Invalid invitation code";
      }
    }
  }
};
</script>
