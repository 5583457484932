<template>
  <v-container class="fill-height registration" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <h1 class="section-title text-center">
          Welcome to Uwill
        </h1>
        <h4 class="text-center">Please complete your profile to get started</h4>
        <profile-location
          v-model:addressOneValidated="address1"
          v-model:addressTwoValidated="address2"
          v-model:cityValidated="city"
          v-model:zipcodeValidated="zipcode"
          v-model:countryValidated="country"
          v-model:stateValidated="state"
        />
        <v-row v-if="!$methods.isIndependentUser() && require_unique_identifier">
          <v-col cols="12" class="mt-3">
            <v-text-field
              id="profile-unique-identifier"
              v-model="uniqueIdentifier"
              :label="$methods.getStudentWord(true) + ' ID'"
              :aria-label="$methods.getStudentWord(true) + ' ID'"
              name="uniqueIdentifier"
              prepend-icon="fa-light fa-id-badge"
              type="text"
              required
              hint="Required"
              persistent-hint
              :error-messages="uniqueIdentifierErrorMessages"
              @update:modelValue="delayTouch(v$.uniqueIdentifier)"
            />
          </v-col>
        </v-row>
        <v-row v-if="!userBirthdate">
          <v-col cols="12" class="mt-3">
            <v-text-field
              id="profile-birth-date"
              v-model="birthDate"
              label="Birth Date"
              aria-label="Birth Date"
              prepend-icon="fa-light fa-cake-candles"
              type="text"
              maxLength="10"
              :required="!isContractor"
              :rules="[birthDateAndGraduationRule]"
              :hint="isContractor ? '' : 'Required. Example: 2000-01-15.'"
              persistent-hint
              :error-messages="birthDateErrorMessages"
              autocomplete="birth date"
              @update:model-value="updateBirthDate(birthDate)"
              @blur="v$.birthDate.$touch()"
            />
          </v-col>
        </v-row>
        <profile-contact
          v-model:contact1NameValidated="contact1Name"
          v-model:contact1TelephoneValidated="contact1Telephone"
          v-model:contact2NameValidated="contact2Name"
          v-model:contact2TelephoneValidated="contact2Telephone"
          :is-provider="false"
          :user-telephone="telephone"
          @update:contact1-name="contact1Name = $event"
          @update:contact2-name="contact2Name = $event"
        />
        <v-row class="optional-information-holder">
          <v-col cols="12">
            <strong>Additional Questions / Optional</strong>
            <div class="text-caption">We will use the information to cater your Uwill experience and help the providers you select know more about you.</div>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    id="profile-preferred-name"
                    v-model="preferredName"
                    label="Your Preferred Name"
                    aria-label="Your Preferred Name"
                    prepend-icon="fa-light fa-id-card-clip"
                    hint="If different than your first name, tell us how you would like to be addressed by your provider."
                    persistent-hint
                    type="text"
                    :error-messages="preferredNameErrorMessages"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    id="profile-gender"
                    v-model="gender"
                    :items="gender_choices"
                    label="Your Gender"
                    aria-label="Your Gender"
                    value:
                    hide-details
                    prepend-icon="fa-light fa-transgender"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    id="profile-pronouns"
                    v-model="pronouns"
                    :items="pronoun_choices"
                    item-value="value"
                    item-title="text"
                    :menu-props="{ maxHeight: '400' }"
                    label="Your Pronouns"
                    aria-label="Your Pronouns"
                    prepend-icon="fa-light fa-transgender"
                  ></v-select>
                </v-col>
                <v-col v-if="!employer" cols="12">
                  <v-text-field
                    id="profile-graduation"
                    v-model="graduationDate"
                    label="Graduation or Degree Completion Date"
                    aria-label="Graduation or Degree Completion Date"
                    prepend-icon="fa-light fa-school"
                    type="text"
                    maxLength="10"
                    required
                    :error-messages="graduationDateErrorMessages"
                    persistent-hint
                    :rules="[birthDateAndGraduationRule]"
                    hint="If applicable. Example: 2000-01-15."
                    autocomplete="graduation date"
                    @update:model-value="updateGraduationDate(graduationDate)"
                    @blur="v$.graduationDate.$touch()"
                  >
                    <template #append>
                      <div>
                        <v-checkbox
                          id="profile-graduation-skip"
                          v-model="skip_graduation_date"
                          label="n/a"
                          aria-label="Not Applicable"
                          hide-details
                          style="margin-top:0px;"
                        />
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    id="profile-ethnicity"
                    v-model="ethnicity"
                    :items="ethnicity_choices"
                    item-value="value"
                    item-title="text"
                    :menu-props="{ maxHeight: '400' }"
                    label="Which ethnicity do you most closely identify with?"
                    aria-label="Which ethnicity do you most closely identify with?"
                    multiple
                    hint="Choose as many as are relevant."
                    persistent-hint
                    prepend-icon="fa-light fa-earth"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    id="profile-reason"
                    v-model="reasons"
                    :items="reason_choices"
                    item-value="value"
                    item-title="text"
                    :menu-props="{ maxHeight: '400' }"
                    label="Reason(s) for using Uwill"
                    aria-label="Reason(s) for using Uwill"
                    multiple
                    hint="Choose as many as you'd like"
                    persistent-hint
                    prepend-icon="fa-light fa-list-check"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    id="profile-focus-areas"
                    v-model="focusAreas"
                    :items="focus_area_choices"
                    :menu-props="{ maxHeight: '400' }"
                    label="Focus Areas"
                    aria-label="Focus Areas"
                    multiple
                    hint="Choose as many as you'd like"
                    persistent-hint
                    prepend-icon="fa-light fa-bullseye-pointer"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    id="profile-about"
                    v-model="about"
                    :label="
                      'Anything you\'d like a ' +
                        $t('text.provider') +
                        ' to know about you?'
                    "
                    :aria-label="
                      'Anything you\'d like a ' +
                        $t('text.provider') +
                        ' to know about you?'
                    "
                    no-resize
                    :hint="
                      'This will only be shared with a ' +
                        $t('text.provider') +
                        ' after you book an appointment'
                    "
                    persistent-hint
                    rows="3"
                    variant="outlined"
                    prepend-icon="fa-light fa-message-lines"
                    type="text"
                    counter
                    required
                    :error-messages="aboutErrorMessages"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <div class="text-center mt-4 mb-4">
          <v-btn
            id="register-finish"
            class="uwill-primary-button padded shadowed"
            style="width:80%;"
            :loading="loading"
            @click="finish()"
          >
            Finish
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
// import Profile from "@/components/Profile.vue";
import useVuelidate from "@vuelidate/core";
import validationMixin from "vuelidate";
import { uwillProfileWrapperMixin } from "./profile/profile-wrapper.js";
import { uwillValidatorMixin } from "../components/form/uwillValidatorMixin.js";
import uwillProfileValidationsMixin from "../components/form/uwillProfileValidationsMixin";
import ProfileLocation from "@/components/profile/Location.vue";
import ProfileContact from "@/components/profile/Contact.vue";

const touchMap = new WeakMap();

export default {
  name: "CompleteProfile",
  components: {
    ProfileLocation,
    ProfileContact
  },
  mixins: [uwillProfileWrapperMixin, validationMixin, uwillValidatorMixin, uwillProfileValidationsMixin],
  props: ["userTelephone", "userBirthdate"],
  setup: () => ({ v$: useVuelidate() }),
  data: () => ({
    confirmed: false,
    activePicker: null,
    birthDate: null,
    employer: false,
    gender: "",
    pronouns: "No Preference",
    telephone: "",
    gender_choices: ["Female", "Male", "Non-Binary", "Prefer not to say"],
    ethnicity: [],
    ethnicity_choices: [],
    reasons: null,
    focusAreas: [],
    about: "",
    uniqueIdentifier: "",
    require_unique_identifier: false,
    graduationDate: null,
    skip_graduation_date: false,
    requiredFields: [
      "address1",
      "city",
      "country",
      "state",
      "zipcode",
      "contact1Telephone",
      "contact1Name",
      "contact2Telephone",
      "contact2Name"
    ],
  }),
  mounted: function() {
    this.telephone = this.userTelephone;
    axios({
      method: "get",
      url: "/fieldvalues"
    }).then(resp => {
      const ethnicities = resp.data.ethnicities;
      ethnicities.unshift("Prefer not to say");
      // Polish the text but not value (so API doesn't get confused)
      this.ethnicity_choices = ethnicities.map((ethnicity) => {
        let ethnicityText = ethnicity.replace("Or", "or"); // Black or African American
        return { value: ethnicity, text: ethnicityText }
      });

      this.reason_choices = resp.data.reason_translations;
      this.pronoun_choices = resp.data.pronouns;
      this.focus_area_choices = resp.data.focus_areas;
      this.language_choices = resp.data.languages;
      this.employer = resp.data.employer;
      this.require_unique_identifier = resp.data.require_unique_identifier;
    });
    window.scrollTo(0,0);
  },
  computed: {
    preferredNameErrorMessages() {
      this.preferredName;
      return this.maxLengthErrors("preferredName");
    },
    aboutErrorMessages() {
      this.about;
      return this.maxLengthErrors("about");
    },
    basicFormProblems() {
      let problems = [];
      for (let i = 0; i < this.requiredFields.length; i++) {
        let field = this.requiredFields[i];
        let value = this[field];

        // Required
        if (value == null || value === "") {
          if (field == "state" && !["United States", "Canada"].includes(this.country)) {
            // Noop - State is not required for countries outside the US & Canada
          } else {
            if (field == "state" && this.country == "Canada") {
              problems.push("Province");
            } else {
              problems.push(this.toSentenceCase(field));
            }
          }
        } else {
          // Special cases like length
          if (field == "zipcode" && this.zipcode.length !== 5) {
            problems.push("Zip");
          }
        }
      }

      if (!this.$methods.isIndependentUser() && this.require_unique_identifier) {
        if (this.uniqueIdentifier == null || this.uniqueIdentifier == "") {
          problems.push(this.$methods.getStudentWord(true) + " ID");
        }        
      }

      if (!this.userBirthdate) {
        // Also validate birthdate
        if (this.birthDate == null) {
          problems.push("Birthdate");
        }
      }
      return problems;
    },
    basicFormValid() {
      return this.basicFormProblems.length == 0;
    },
    birthDateErrorMessages() {
      this.birthDate;
      return this.requiredErrors("birthDate");
    },
    graduationDateErrorMessages() {
      this.graduationDate;
      if (this.skip_graduation_date) return [];
      return this.requiredErrors("graduationDate");
    },
    birthDateAndGraduationRule() {
      return birthDate => {
        const invalidMessage = "Please enter a valid date. Example: 2000-01-15.";

        // Example: 2000-01-15
        // Year should have exactly 4 characters
        // Month and day should each have either 1 or 2 characters
        const birthDateRegex = /^\d{4}-\d{1,2}-\d{1,2}$/;
        if (!birthDateRegex.test(birthDate)) return invalidMessage;

        // At this point, birthDate has 2 dashes and only numbers

        // Split birth date to test for min and max of year, month, and day
        const splitBirthDate = birthDate.split("-");
        const birthYear = parseInt(splitBirthDate[0]);
        const birthMonth = parseInt(splitBirthDate[1]);
        const birthDay = parseInt(splitBirthDate[2]);

        // Year out of bounds
        const currentYear = new Date().getFullYear();
        if (birthYear < 1920 || birthYear > currentYear) return invalidMessage;

        // Month out of bounds
        if (birthMonth < 1 || birthMonth > 12) return invalidMessage;

        // Day out of bounds
        if (birthDay < 1 || birthDay > 31) return invalidMessage;

        // At this point, birth date valid
        return true;
      };
    },
  },
  watch: {
    birthDate(val) {
      // Replace all / with - while user is typing
      // Before replacement: 2020/01/15
      // After replacement: 2020-01-15
      const refinedBirthDate = val.replaceAll("/", "-");
      this.birthDate = refinedBirthDate;
    },
    graduationDate(val) {
      // Replace all / with - while user is typing
      // Before replacement: 2020/01/15
      // After replacement: 2020-01-15
      const refinedGraduationDate = val.replaceAll("/", "-");
      this.graduationDate = refinedGraduationDate;
    }
  },
  methods: {
    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },    
    telephoneErrorMessages() {
      this.telephone;
      return this.telephoneErrors("telephone");
    },
    updateGraduationDate(event) {
      this.$emit("update:graduationDateValidated", event);
    },
    updateBirthDate(event) {
      this.$emit("update:birthDateValidated", event);
    },
    uniqueIdentifierErrors() {
      if (this.v$.uniqueIdentifier != null && !this.v$.uniqueIdentifier.$dirty) {
        return [];
      }
      let errors = this.requiredErrors("uniqueIdentifier", false);
      !this.v$.uniqueIdentifier.minLength &&
        errors.push(this.$methods.getStudentWord(true) + " ID must be at least 4 characters long");    
        
      let emitValue = null;
      if (errors.length == 0) {
        emitValue = this.v$.uniqueIdentifier.$model;
      }
      this.$emit("update:uniqueIdentifierValidated", emitValue);
      return errors;
    },    
    resendConfirmation: function() {
      new Promise(resolve => {
        axios({
          method: "post",
          url: "/resendconfirmation"
        }).then(resp => {
          window.Bus.$emit("flash-message", {
            text: resp.data.message
          });
          resolve();
        });
      });
    },
    finish: function() {
      // Make sure all the required fields are valid
      if (this.basicFormValid) {
        // Submit the form
        let form_attributes = {
          address1: this.address1,
          address2: this.address2,
          city: this.city,
          zipcode: this.zipcode,
          focus_areas: this.focusAreas,
          state: this.state,
          country: this.country,
          about: this.about,
          preferred_name: this.preferredName,
          gender: this.gender,
          telephone: this.telephone,
          graduation_date: this.graduationDate,
          reasons: this.reasons,
          pronouns: this.pronouns,
          contact_1_name: this.contact1Name,
          contact_2_name: this.contact2Name,
          contact_1_telephone: this.contact1Telephone,
          contact_2_telephone: this.contact2Telephone,
          ethnicity: this.ethnicity,
          unique_identifier: this.uniqueIdentifier
        };
        if (this.birthDate) {
          form_attributes["birthdate"] = this.birthDate;
        }
        let formData = new FormData();
        Object.entries(form_attributes).forEach(([key, value]) =>
          formData.append(key, value)
        );
        axios
          .put("/user", formData, {})
          .then(resp => {
            window.Bus.$emit("flash-message", {
              text: resp.data.message
            });
            this.$store.dispatch("get_user");
          });
      } else {
        let problems = new Intl.ListFormat().format(this.basicFormProblems);
        window.Bus.$emit("flash-message", {
          text: "Please fill out all the required fields: " + problems
        });
      }

    }
  }
};
</script>
