import {
  required,
  requiredUnless,
  minLength,
  maxLength,
  email
} from "vuelidate/lib/validators";
import axios from "axios";
export default {
  validations: {
    password: {
      required,
      minLength: minLength(8),
      complexity: function(value) {
        return /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/.test(
          value
        );
      }
    },
    email: {
      required,
      email,
      isUnique(value) {
        if (value === "") return true;
        return new Promise(resolve => {
          axios({
            method: "post",
            url: "/emailunique",
            data: {
              email: value
            }
          }).then(resp => {
            resolve(resp.data.unique);
          });
        });
      },
      isValidOrganization(value) {
        if (this.isProvider) {
          return true;
        }
        if (
          value == null ||
          value === "" ||
          value.indexOf("@") === -1 ||
          value.indexOf(".") === -1
        )
          return true;
        return new Promise(resolve => {
          axios({
            method: "post",
            url: "/validorganization",
            data: {
              email: value,
              invitation_code: this.invitation_code
            }
          }).then(resp => {
            this.$emit("organizations-update", resp.data);
            resolve(resp.data.valid);
          });
        });
      }
    },
    city: { required },
    zipcode: {
      required,
      minLength: minLength(4)
    },
    address1: { required },
    address2: {},
    country: { required },
    firstName: {
      required: requiredUnless(function() {
        return this.anonymous == true;
      })
    },
    lastName: {
      required: requiredUnless(function() {
        return this.anonymous == true;
      })
    },
    preferredName: { maxLength: maxLength(255) },
    birthDate: { required },
    photo: { required },
    uniqueIdentifier: {
      required,
      minLength: minLength(4)
    },
    bio: { required, maxLength: maxLength(750) },
    introductionEmail: { maxLength: maxLength(750) },
    about: { maxLength: maxLength(750) },
    contact1Name: { required },
    contact1Telephone: {
      required,
      isPhone(value) {
        if (value === "") return true;
        let telephoneRegEx = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
        return telephoneRegEx.test(value);
      }
    },
    contact2Name: { required },
    contact2Telephone: {
      required,
      isPhone(value) {
        if (value === "") return true;
        let telephoneRegEx = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
        return telephoneRegEx.test(value);
      }
    },
    telephone: {
      required,
      isPhone(value) {
        if (value === "") return true;
        let telephoneRegEx = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
        return telephoneRegEx.test(value);
      }
    },
    serviceYears: { required },
    specialty: {},
    gender: { required },
    designation: { required },
    graduationDate: { required }
  }
};
