import { createApp } from "vue";
import emitter from "tiny-emitter/instance";
import amplitude from "amplitude-js";
import { createI18n } from "vue-i18n";
import * as otherVue2Filters from "vue2-filters/src/other/index";
import * as stringVue2Filters from "vue2-filters/src/string/index";
import "./plugins/axios";
import store from "./store";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./styles/theme.scss";
import "./styles/alert.scss";
import { ENGLISH_TRANSLATIONS } from "./translations/en";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { createMetaManager } from "vue-meta";
import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import "vue3-snackbar/styles";
import VCalendar from "v-calendar";
import "v-calendar/style.css";
import PortalVue from "portal-vue";
import axios from "axios";

// TODO: Resolve this for Vue 3
// export const uwillProfileCategoryMixin = {
//   mixins: [validationMixin, uwillValidatorMixin],
//   props: {
//     mode: {
//       default: "create",
//       type: String
//     },
//     isProvider: {
//       default: false,
//       type: Boolean
//     }
//   },
//   computed: {
//     threePerRowColumns: function() {
//       return this.mode == "create" ? 12 : 4;
//     },
//     twoPerRowColumns: function() {
//       return this.mode == "create" ? 12 : 6;
//     }
//   }
// };

const TRANSLATIONS = {
  en: ENGLISH_TRANSLATIONS
};
const i18n = createI18n({
  locale: "en",
  messages: TRANSLATIONS
});

const app = createApp(App);

// Use vc-calendar component
// Vuetify already comes with v-calendar, so set this calendar component to vc-calendar
app
  .use(VCalendar, {
    componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
    navVisibility: "visible"
  })
  .use(i18n)
  .use(createMetaManager())
  .use(PortalVue)
  .use(SnackbarService)
  .use(vuetify);

// Component registration
// Without this (or some of this), FlashMessage doesn't work
app
  .component("FlashMessage", require("@/components/FlashMessage.vue").default)
  .component("show-error", require("@/components/ShowError.vue").default)
  .component("vue3-snackbar", Vue3Snackbar)
  .component("help", require("@/components/Help.vue").default);

// An external package (tiny-emitter) is now required for event bus pattern with Vue 3
// This also supports off and once, but they're excluded for now since they're not consumed
window.Bus = {
  $emit: (...args) => emitter.emit(...args),
  $on: (...args) => emitter.on(...args)
};

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    autoSessionTracking: true,
    logErrors: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

// Globals

amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_API);
app.config.globalProperties.$amplitude = amplitude; // this.$amplitude.getInstance().logEvent("event")

// Methods
app.config.globalProperties.$methods = {
  emitField: async function(key, value = null) {
    if (value == null) {
      value = this[key];
    }
    emitter.emit("emit-field", key, value);
  },
  updateField: function(key, value) {
    if (this[key] !== value) this[key] = value;
  },
  home: function() {
    location.href = "/";
  },
  toggleHelp() {
    this.$methods.changePreference("help");
  },
  remove2FA() {
    let confirmation = confirm(
      "Are you sure you want to remove Two-Factor Authentication? You can always re-add it."
    );
    if (confirmation) {
      axios({
        method: "post",
        url: "/users/unset2fa"
      }).then(resp => {
        window.Bus.$emit("flash-message", {
          text: resp.data.message
        });
        this.$store.dispatch("get_user");
        this.$router.push("/dashboard");
      });
    }
  },
  getStripeLink() {
    axios({
      method: "get",
      url: "/stripeloginlink"
    }).then(resp => {
      if (resp.data.errors) {
        window.Bus.$emit("flash-message", {
          text: resp.data.errors
        });
      } else {
        window.open(resp.data.url);
      }
    });
  },
  encodedTimezone() {
    return encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone);
  },
  hasUserOptedOutOf(token) {
    //False = User has Opted out; True = need to recheck this token
    let optOut = localStorage.getItem(token);

    if (optOut == null) {
      return false;
    }
    optOut = new Date(parseInt(localStorage.getItem(token)));
    let now = new Date();
    if (optOut.valueOf() < now.valueOf()) {
      localStorage.removeItem(token);
      return false;
    } else {
      return true;
    }
  },
  setUserOptOutFor(token, duration) {
    let askAgainAfter = new Date();
    askAgainAfter.setDate(askAgainAfter.getDate() + duration); // Disable nag for ${duration} days
    localStorage.setItem(token, askAgainAfter.valueOf());
  },
  getStudentWord(capitalize = null) {
    var word = store.state.user.studentWord
      ? store.state.user.studentWord
      : i18n.global.t("text.student"); // Difficult to access this.$t here, so use global

    if (capitalize) {
      word = word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word;
  },
  getFacultyWord() {
    return i18n.global.t("text.faculty");
  },
  isIndependentUser() {
    return store.state.user.isIndependent;
  },
  updateTimezone(timezone) {
    let formData = new FormData();
    formData.append("timezone", timezone);
    axios
      .put("/user", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(resp => {
        store.state.user.timezone = timezone;
        window.Bus.$emit("flash-message", {
          text: resp.data.message
        });
        store.dispatch("get_user");
      });
  },
  changePreference(name, value = "toggle") {
    let baggage = {
      preference: name
    };
    if (value != "toggle") {
      baggage.value = value;
    }
    axios({
      method: "post",
      url: "/users/" + store.state.user.id + "/changepreference",
      data: baggage
    }).then(resp => {
      window.Bus.$emit("flash-message", {
        text: resp.data.message
      });
      store.dispatch("get_user");
    });
  }
};

// Filters
app.config.globalProperties.$filters = {
  capitalize(value, options) {
    return stringVue2Filters.capitalize(value, options);
  },
  currency(value, symbol, decimals, options) {
    return otherVue2Filters.currency(value, symbol, decimals, options);
  },
  pluralize(value, word, options) {
    return otherVue2Filters.pluralize(value, word, options);
  },
  phoneNumber(phoneNumber) {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  },
  snakeToTitle(str) {
    return str
      .split("_")
      .map(function(item) {
        return item.charAt(0).toUpperCase() + item.substring(1);
      })
      .join(" ");
  },
  truncate(text, length, suffix) {
    if (text.length > length) {
      return text.substring(0, length) + suffix;
    } else {
      return text;
    }
  }
};

app.config.globalProperties.$states = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "Not currently in the United States"
];

app.config.globalProperties.$provinces = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Nova Scotia",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan"
];

app.config.globalProperties.$countries = [
  "United States",
  "Canada",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Republic of North Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French Part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch Part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

// Ensure the entire app has access to plugins
// Mount the root instance
app
  .use(router)
  .use(store)
  .mount("#app");
