<template>
  <div class="text-center">
    <v-dialog v-model="error_dialog" :width="width">
      <v-card
        role="alertdialog"
        :aria-label="title"
      >
        <v-card-title
          class="text-h5 bg-grey-lighten-2"
          role="heading"
          aria-level="1"
          primary-title
        >
          {{ title }}
        </v-card-title>

        <v-card-text class="pb-0">
          <v-card-subtitle
            v-if="description"
            class="pb-0"
            role="heading"
            aria-level="2"
            >{{ description }}</v-card-subtitle
          >
          <v-list v-if="arrayOfErrors">
            <v-list-item v-for="(error, i) in errors" :key="i">
              {{ error }}
            </v-list-item>
          </v-list>
          <v-card-text v-else class="mt-2">
            {{ errors }}
          </v-card-text>
        </v-card-text>

        <v-divider class="mb-0"></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="uwill-primary-button" @click="error_dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ShowError",
  data: () => ({
    errors: null,
    title: null,
    error_dialog: false,
    width: null,
    description: null
  }),
  computed: {
    arrayOfErrors: function() {
      return this.errors && Array.isArray(this.errors);
    }
  },
  mounted: function() {
    window.Bus.$on(
      "error-message",
      (errors, dialog_title, dialog_width, error_description) => {
        this.errors = errors;
        this.title = dialog_title ? dialog_title : "Oops...";
        this.width = dialog_width ? dialog_width : 500;
        this.error_dialog = true;
        this.description = error_description;
      }
    );
  }
};
</script>
