import { createRouter, createWebHistory } from "vue-router";
import ShowError from "@/components/ShowError.vue";
import StripeOauth from "@/components/StripeOauth.vue";
import StripeLink from "@/components/StripeLink.vue";
import store from "@/store";

let routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/components/Home.vue"),
    meta: {
      requiresLogout: true,
      title: "Home | Uwill"
    }
  },
  {
    path: "/2fa",
    name: "TwoFactor",
    component: () => import("@/components/TwoFactor.vue"),
    meta: {
      title: "Set up Two Factor Authentication | Uwill"
    }
  },
  {
    path: "/mfa",
    name: "MultiFactorAuthentication",
    component: () => import("@/components/MultifactorAuthentication.vue"),
    meta: {
      title: "Device Authentication | Uwill",
      mfa: true
    }
  },
  {
    path: "/dashboard",
    name: "UserDashboard",
    component: () => import("@/components/user/UserLayout.vue"),
    meta: {
      requiresAuth: true,
      title: "Dashboard | Uwill"
    },
    props: route => ({ tab: route.query.tab })
  },
  {
    path: "/collaborate",
    name: "Collaborate",
    component: () => import("@/components/Collaborate.vue"),
    meta: {
      requiresOrganization: true,
      title: "Organization Dashboard | Uwill"
    }
  },
  {
    path: "/crisis",
    name: "Operator",
    component: () => import("@/components/uhelp/OperatorDashboard.vue"),
    meta: {
      requiresOperator: true,
      title: "Crisis Worker Dashboard | Uwill"
    }
  },
  {
    path: "/appointment/:id",
    name: "Appointment",
    component: () => import("@/components/Appointment.vue"),
    meta: {
      requiresAuth: true,
      title: "Appointment Information | Uwill"
    }
  },
  {
    path: "/proposal/:token/:proposal_action",
    name: "AppointmentProposal",
    component: () => import("@/components/AppointmentProposal.vue"),
    meta: {
      requiresAuth: true,
      title: "Appointment Proposal | Uwill"
    }
  },
  {
    path: "/rejoin/:token/:reinvitation_action",
    name: "Reinvitation",
    component: () => import("@/components/Reinvitation.vue"),
    meta: {
      requiresAuth: true,
      title: "Re-Join Organization | Uwill"
    }
  },
  {
    path: "/createappointment/:id",
    name: "CreateAppointment",
    component: () => import("@/components/CreateAppointment.vue"),
    meta: {
      requiresAuth: true,
      title: "Create an Appointment | Uwill"
    }
  },
  {
    path: "/update-appointment/:id",
    name: "UpdateAppointment",
    component: () => import("@/components/UpdateAppointment.vue"),
    meta: {
      requiresAuth: true,
      title: "Update your Appointment | Uwill"
    }
  },
  {
    path: "/findtherapist",
    name: "FindProvider",
    component: () => import("@/components/FindProvider.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/purchasecredits",
    name: "PurchaseCredits",
    component: () => import("@/components/PurchaseCredits.vue"),
    meta: {
      requiresAuth: true,
      title: "Purchase Credits | Uwill"
    }
  },
  {
    path: "/consent",
    name: "ConsentToTreat",
    component: () => import("@/components/ConsentToTreat.vue"),
    meta: {
      requiresMissingConsent: true,
      title: "Consent to Treat | Uwill"
    }
  },
  {
    path: "/guardianconsent/:guardian/:minor",
    name: "GuardianConsent",
    component: () => import("@/components/GuardianConsent.vue"),
    meta: {
      title: "Guardian Consent | Uwill"
    }
  },
  {
    path: "/ltilaunch",
    name: "LtiLaunch",
    component: () => import("@/components/LtiLaunch.vue"),
    meta: {
      title: "Redirecting to Uwill"
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/components/Login.vue"),
    meta: {
      requiresLogout: true,
      title: "Login | Uwill"
    }
  },
  {
    path: "/register/:token?",
    name: "Register",
    component: () => import("@/components/Register.vue"),
    meta: {
      requiresLogout: true,
      title: "Register to Join | Uwill"
    }
  },
  {
    path: "/confirm/:token",
    name: "ConfirmEmail",
    component: () => import("@/components/ConfirmEmail.vue"),
    meta: {
      title: "Confirm Your Email Address | Uwill"
    }
  },
  {
    path: "/feedback/:id",
    name: "FeedbackWrapper",
    component: () => import("@/components/FeedbackWrapper.vue"),
    meta: {
      requiresAuth: true,
      title: "Appointment Feedback | Uwill"
    }
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: () => import("@/components/ForgotPassword.vue"),
    meta: {
      title: "Forgot Your Password | Uwill"
    }
  },
  {
    path: "/events/:slug",
    name: "EventRegistration",
    component: () => import("@/components/EventRegistration.vue"),
    meta: {
      title: "Event Registration | Uwill"
    }
  },
  {
    path: "/talknow/:token",
    name: "TalkNow",
    component: () => import("@/components/TalkNow.vue"),
    meta: {
      title: "Talk Now | Uwill"
    }
  },
  {
    path: "/talknow",
    name: "CreateTalkNow",
    component: () => import("@/components/CreateTalkNow.vue"),
    meta: {
      title: "Talk Now | Uwill"
    }
  },
  {
    path: "/activity",
    name: "Activity",
    component: () => import("@/components/Activity.vue"),
    meta: {
      requiresAuth: true,
      title: "Activity | Uwill"
    }
  },
  {
    path: "/availability",
    redirect: to => {
      console.log(to);
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: "/dashboard", query: { tab: "availability" } };
    }
  },
  {
    path: "/gcal:afterGcal(.*)",
    name: "GoogleCalendar",
    component: () => import("@/components/GoogleCalendar.vue"),
    meta: {
      requiresAuth: true,
      title: "Integrate your Calendar | Uwill"
    }
  },
  {
    path: "/history",
    name: "history",
    component: () => import("@/components/History.vue"),
    meta: {
      requiresAuth: true,
      title: "History | Uwill"
    }
  },
  {
    path: "/changeover",
    name: "changeover",
    component: () => import("@/components/ChangeOver.vue"),
    meta: {
      requiresAuth: true,
      title: "Account Changeover | Uwill"
    }
  },
  {
    path: "/files",
    name: "files",
    component: () => import("@/components/Files.vue"),
    meta: {
      requiresAuth: true,
      title: "Files | Uwill"
    }
  },
  {
    path: "/resetpassword/:token",
    name: "ResetPassword",
    component: () => import("@/components/ResetPassword.vue"),
    meta: {
      title: "Reset Your Password | Uwill"
    }
  },
  {
    path: "/connect/oauth",
    name: "StripeOauth",
    component: StripeOauth
  },
  {
    path: "/connect/link",
    name: "StripeLink",
    component: StripeLink
  },
  {
    path: "/signon/:token/:otp",
    name: "GlobalSignOn",
    component: () => import("@/components/GlobalSignOn.vue"),
    meta: {
      requiresLogout: true,
      title: "Login | Uwill"
    }
  },
  {
    path: "/demoauth",
    name: "Demo",
    component: () => import("@/components/Demo.vue"),
    meta: {
      title: "Demo | Uwill"
    }
  },
  {
    path: "/logout",
    name: "logout"
  },
  {
    path: "/error",
    name: "ShowError",
    component: ShowError
  },
  {
    path: "/doc/:type",
    name: "Documentation",
    component: () => import("@/components/Documentation.vue")
  },
  {
    // For Student to take a new Self-Assessment
    path: "/assessment",
    name: "SelfAssessment",
    component: () => import("@/components/outcomes/SelfAssessment.vue")
  },

  // For Vue 3, the * path has been split into /:pathMatch(.*)* and /:pathMatch(.*)
  // They're essentially the same, so use both
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/components/Home.vue")
  },
  {
    path: "/:pathMatch(.*)",
    component: () => import("@/components/Home.vue")
  }
];

const host = window.location.host;
if (
  process.env.VUE_APP_LANDING_DOMAIN &&
  host.indexOf(process.env.VUE_APP_LANDING_DOMAIN) != -1
) {
  routes = [
    {
      path: "/",
      name: "Landing",
      component: () => import("@/components/Landing.vue")
    }
  ];
}

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  if (
    (process.env.NODE_ENV === "production" ||
      process.env.NODE_ENV === "staging" ||
      process.env.NODE_ENV === "demo") &&
    location.protocol !== "https:"
  ) {
    location.replace(
      `https:${location.href.substring(location.protocol.length)}`
    );
  }

  // TODO: Uncomment before Vue 3 launch
  if (process.env.NODE_ENV === "demo" && to.name != "Demo") {
    // Check Auth
    let auth = localStorage.getItem("uwill_demo_authorized");
    var demo_auth_redirect = true;
    if (auth) {
      let now = new Date();
      let permitted = Date.parse(auth) > now.valueOf();
      if (permitted) {
        demo_auth_redirect = false;
      } else {
        demo_auth_redirect = true;
        localStorage.removeItem("uwill_demo_authorized");
      }
    }
    if (demo_auth_redirect) {
      next("/demoauth");
    }
  }

  if (to.name == "logout") {
    store.dispatch("logout").then(() => {
      next("/login");
    });
  } else if (
    store.getters.mfaRequired &&
    !to.matched.some(record => record.meta.mfa)
  ) {
    next("/mfa");
  } else if (
    store.getters.isCollaborate &&
    !to.matched.some(record => record.meta.requiresOrganization)
  ) {
    if (
      // Documentation: Privacy Policy and Terms and Conditions of Use
      ["TwoFactor", "Documentation"].includes(to.name) ||
      store.getters.needsTFA
    ) {
      next();
    } else {
      next("/collaborate");
    }
  } else if (
    store.getters.isChangeover &&
    to.name != "changeover" &&
    store.getters.isConfirmed
  ) {
    next("/changeover");
  } else if (
    store.getters.isOperator &&
    !to.matched.some(record => record.meta.requiresOperator)
  ) {
    if (to.name == "TwoFactor" || store.getters.needsTFA) {
      next();
    } else {
      next("/crisis");
    }
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.needsTFA) {
      next("/2fa");
    } else if (store.getters.isLoggedIn) {
      next();
    } else {
      next("/login");
    }
  } else if (to.matched.some(record => record.meta.requiresLogout)) {
    if (store.getters.isLoggedIn) {
      next("/dashboard");
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresMissingConsent)) {
    if (!store.getters.isLoggedIn) {
      next("/login");
    } else if (store.state.user.consentToTreat) {
      next("/dashboard");
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.demoInterstitial)) {
    next();
  } else {
    next();
  }
});

export default router;
