import { createVuetify } from "vuetify";
// TODO: Confirm whether styles and directives are necessary for Vue 3
import "vuetify/styles";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, fa } from "vuetify/iconsets/fa";
import { mdi } from "vuetify/iconsets/mdi";
import { VCalendar } from "vuetify/labs/VCalendar";
import { VTimePicker } from "vuetify/labs/VTimePicker";
import { VBtn } from "vuetify/components/VBtn";
// Vuetify Labs components in development
// Includes: <v-data-iterator>, and <v-skeleton-loader>
import * as labComponents from "vuetify/labs/components";

const vuetify = createVuetify({
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: "#6667AB",
          secondary: "#edb740",
          error: "#CB3161",
          bodyColor: "#ff0000",
          chipColor: "#1976d275",
          uwillOrange: "#ffcc33",
          uwillPrimary: "#6667AB",
          uwillYellow: "#edb740",
          uwillPeri: "#6364BA",
          uwillPrimaryLight: "#999AC7",
          uwillBluegreen: "#587B84",
          uwillLightGrey: "#EFEFF8",
          uwillAlert: "#CB3161",
          uwillSuccess: "#49A248",
          uwillWarning: "#EA7A0F",
          uwillDarkGrey: "rgba(10, 10, 19, 0.6)"
        }
      }
    },
    icons: {
      defaultSet: 'fa',
      aliases,
      sets: {
        mdi,
        fa
      }
    }
  },
  components: {
    ...components,
    ...labComponents,
    VCalendar,
    VTimePicker
  },
  directives,
  icons: {
    defaultSet: "fa",
    aliases: aliases,
    sets: {
      fa: fa,
      mdi: mdi
    }
  },
  defaults: {
    VMain: {
      class: "pt-16"
    },
    VBtnSecondary: {
      class: "uwill-secondary-button"
    }
  },
  aliases: {
    VBtnSecondary: VBtn
  }
});

export default vuetify;
