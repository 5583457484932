<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="mx-auto" border>
          <v-list-item lines="three">
            <div class="text-overline mb-4">Waiting for verification...</div>
            <v-list-item-title class="text-h5 mb-1">
              Activate your account
            </v-list-item-title>
            <v-list-item-subtitle>
              We sent an email to
              <span class="font-italic font-weight-bold">{{
                $store.state.user.email
              }}</span
              >.
            </v-list-item-subtitle>
            <v-list-item-subtitle class="mt-2 mb-2">
              Please check your email to verify and activate your account.
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              If you don't see it soon, please make sure to
              <em>check your junk/spam folder</em>.
            </v-list-item-subtitle>
          </v-list-item>

          <v-card-actions>
            <v-btn variant="text" @click="resendConfirmation()">
              Resend the activation email
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "Confirmation",
  data: () => ({
    confirmed: false
  }),
  methods: {
    resendConfirmation: function() {
      new Promise(resolve => {
        axios({
          method: "post",
          url: "/resendconfirmation"
        }).then(resp => {
          window.Bus.$emit("flash-message", {
            text: resp.data.message
          });
          resolve();
        });
      });
    }
  }
};
</script>
