import { uwillValidatorMixin } from "../form/uwillValidatorMixin.js";
export const uwillProfileCategoryMixin = {
  mixins: [uwillValidatorMixin],
  props: {
    mode: {
      default: "create",
      type: String
    },
    isProvider: {
      default: false,
      type: Boolean
    },
    isInstructor: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    threePerRowColumns: function() {
      return this.mode == "create" ? 12 : 4;
    },
    twoPerRowColumns: function() {
      return this.mode == "create" ? 12 : 6;
    }
  }
};
