export const ENGLISH_TRANSLATIONS = {
  text: {
    therapist: "therapist",
    mindfulness_coach: "mindfulness coach",
    provider: "provider",
    psychiatrist: "psychiatrist",
    faculty: "faculty",
    student: "student",
    resident: "resident"
  }
};
