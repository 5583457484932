<template>
  <v-row>
    <v-col cols="12" class="mt-3">
      <v-text-field
        id="profile-address1"
        v-model="address1"
        label="Address 1"
        aria-label="Address 1"
        type="text"
        hint="Required"
        persistent-hint
        :error-messages="addressOneErrorMessages"
        @update:model-value="v$.address1.$touch()"
        @blur="v$.address1.$touch()"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        id="profile-address2"
        v-model="address2"
        label="Address 2"
        aria-label="Address 2"
        type="text"
        @update:model-value="updateAddress2"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        id="profile-city"
        v-model="city"
        label="City"
        aria-label="City"
        type="text"
        hint="Required"
        persistent-hint
        :error-messages="cityErrorMessages"
        @update:model-value="v$.city.$touch()"
        @blur="v$.city.$touch()"
      />
    </v-col>
    <v-col cols="12" md="4">
      <v-select
        id="profile-country"
        v-model="country"
        :items="$countries"
        label="Country"
        aria-label="Country"
        hint="Required"
        persistent-hint
        prepend-icon="fa-light fa-location-dot"
        @update:model-value="updateCountry"
      ></v-select>
    </v-col>
    <v-col cols="12" md="4">
      <v-select
        id="profile-student-state"
        v-model="state"
        :items="showProvinces ? $provinces : $states"
        :label="(showProvinces ? 'Province' : 'State') + ' (current location)'"
        :aria-label="(showProvinces ? 'Province' : 'State') + ' (current location)'"
        :hint="
          showStates || showProvinces
            ? `We show ${$t(
                'text.provider'
              )}s who are appropriate for you based on your location.`
            : 'Based on the country you selected, this field is not necessary'
        "
        persistent-hint
        prepend-icon="fa-light fa-location-dot"
        :disabled="!showStates && !showProvinces"
        @update:model-value="updateState"
      ></v-select>
    </v-col>
    <v-col sm="12" md="4">
      <v-text-field
        id="profile-zipcode"
        v-model="zipcode"
        label="Zip"
        aria-label="Zip"
        type="text"
        hint="Required"
        persistent-hint
        prepend-icon="fa-light fa-location-dot"
        maxLength="5"
        :error-messages="zipcodeErrorMessages"
        @update:model-value="updateZipcode"
        @blur="v$.zipcode.$touch()"
      />
    </v-col>
  </v-row>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { uwillProfileCategoryMixin } from "./profile-category.js";

export default {
  name: "ProfileLocation",
  mixins: [uwillProfileCategoryMixin],
  props: [
    "zipcodeValidated",
    "addressOneValidated",
    "addressTwoValidated",
    "cityValidated",
    "stateValidated",
    "countryValidated"
  ],
  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    zipcode: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: ""
  }),
  computed: {
    addressOneErrorMessages() {
      this.address1;
      let errors = [];
      if (this.v$.address1 != null && !this.v$.address1.$dirty) {
        return errors;
      }

      if (this.v$.address1.required.$invalid) {
        errors.push("Address 1 is required");
      }
      let emitValue = null;
      if (errors.length == 0) {
        // TODO: Confirm this $model still works after Vue 3 migration (here and elsewhere)
        emitValue = this.v$.address1.$model;
      }
      this.$emit("update:addressOneValidated", emitValue);
      return errors;
    },
    cityErrorMessages() {
      let errors = [];
      if (this.v$.city != null && !this.v$.city.$dirty) {
        return errors;
      }

      if (this.v$.city.required.$invalid) {
        errors.push("City is required");
      }

      let emitValue = null;
      if (errors.length == 0) {
        emitValue = this.v$.city.$model;
      }
      this.$emit("update:cityValidated", emitValue);
      return errors;
    },
    zipcodeErrorMessages() {
      let errors = [];
      if (this.v$.zipcode != null && !this.v$.zipcode.$dirty) {
        return errors;
      }

      // Required
      if (this.v$.zipcode.required.$invalid) {
        errors.push("Zip is required");
      }

      // Exactly 5 characters
      // HTML property takes care of preventing more than 5 characters, so no need to call it out
      if (this.v$.zipcode.minLength.$invalid || this.v$.zipcode.maxLength.$invalid) {
        errors.push("Zip must be at least 5 characters long");
      }

      let emitValue = null;
      if (errors.length == 0) {
        emitValue = this.v$.zipcode.$model;
      }
      this.$emit("update:zipcodeValidated", emitValue);
      return errors;
    },
    showProvinces() {
      return this.country == "Canada";
    },
    showStates() {
      return this.country == "United States";
    }
  },
  watch: {
    graduationDateMenu(val) {
      val && setTimeout(() => (this.activePicker = "MONTH"));
    },
    skip_graduation_date(val) {
      if (val) {
        this.updateGraduationDate(false);
      } else {
        this.updateGraduationDate(this.graduationDate);
      }
    }
  },
  mounted: function() {
    this.zipcode = this.zipcodeValidated;
    this.address1 = this.addressOneValidated;
    this.address2 = this.addressTwoValidated;
    this.city = this.cityValidated;
    this.state = this.stateValidated;
    this.country = this.countryValidated;
  },
  methods: {
    updateCountry(event) {
      this.$emit("update:countryValidated", event);
    },
    updateAddress2(event) {
      this.$emit("update:addressTwoValidated", event);
    },
    updateZipcode(event) {
      this.$emit("update:zipcodeValidated", event);
    },
    updateState(event) {
      this.$emit("update:stateValidated", event);
    }
  }
};
</script>
