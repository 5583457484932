<template>
  <div class="text-center mt-5">
    <h1 class="mb-5">Setting up your payment information...</h1>
    <v-progress-circular
      :size="70"
      :width="7"
      color="#1976d275"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "StripeOauth",
  data: () => ({
    message: null
  }),
  mounted: function() {
    axios({
      method: "post",
      url: "/stripeconnect",
      data: {
        code: this.$route.query.code,
        state: this.$route.query.state
      }
    }).then(resp => {
      window.Bus.$emit("flash-message", {
        text: resp.data.message || resp.data.error
      });
      this.$router.push("/dashboard");
    });
  }
};
</script>
