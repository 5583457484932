/* eslint-disable */
import axios from "axios";
export const DASHBOARD = (state) => {
  state.isDashboard = true;
};
export const DASHBOARD_FALSE = (state) => {
  state.isDashboard = false;
};
export const TFA = (state, data) => {
  state.needsTFA = data ? true : false;
};
export const MFA_REQUIRED = (state, data) => {
  state.mfaRequired = data ? true : false;
};
export const MFA_AUTHENTICATED = (state) => {
  state.mfaRequired = false;
};
export const CHANGEOVER = (state) => {
  state.isChangeover = true;
};
export const COLLABORATE = (state) => {
  state.isCollaborate = true;
};
export const INCOMPLETE = (state) => {
  state.isIncomplete = true;
};
export const OPERATOR = (state) => {
  state.isOperator = true;
};
export const AUTH_REQUEST = (state) => {
  state.status = "loading";
};
export const AUTH_SUCCESS = (state, data) => {
  state.status = "success";
  state.token = data.token;
  state.user = data.user;
  state.isConfirmed = data.user.confirmed;
};
export const AUTH_ERROR = (state) => {
  state.status = "error";
};
export const USER_REQUEST = (state) => {
  state.status = "requesting_user";
};
export const USER_REQUEST_SUCCESS = (state, data) => {
  state.status = "success";
  state.user = data.user;
  state.isConfirmed = data.user.confirmed;
  state.isChangeover = data.user.changeover;
};
export const USER_REQUEST_ERROR = (state) => {
  state.status = "error";
};
export const CONFIRMATION_REQUESTED = (state, data) => {
  state.isConfirmed = data.user.confirmed;
}
export const REGISTER_REQUEST = (state) => {
  state.status = "registering";
};
export const REGISTER_SUCCESS = (state, data) => {
  state.status = "success";
  state.token = data.token;
  state.user = data.user;
  state.isConfirmed = data.user.confirmed;
};
export const REGISTER_ERROR = (state) => {
  state.status = "error";
};
export const THERAPIST_RESULTS = (state, data) => {
  state.therapists = data.therapists;
};
export const PROVIDER_RESULTS = (state, data) => {
  state.providers = data.providers;
};
export const INVITATION_CODE = (state, data) => {
  state.invitation_code = data;
};
export const UPDATE_TIMEZONE = (state, data) =>{
  state.user.timezone = data;
};
export const TOGGLE_VACATION = (state) =>{
  state.user.vacation=!state.user.vacation;
};
export const LOGOUT = (state) => {
  state.status = "";
  state.token = null;
  state.user = {};
  state.therapists = null;
  state.providers = null;
  state.isCollaborate = false;
  state.isOperator = false;
  state.needsTFA = false;
  state.mfaRequired = false;
  state.isChangeover = false;
  state.isConfirmed = false;
  state.isIncomplete = false;
  state.isDashboard = false;
  state.invitation_code = null;
  localStorage.removeItem("uwillToken");
  localStorage.removeItem("uwillImpersonation");
  localStorage.removeItem("uwill_demo_authorized");
  delete axios.defaults.headers.common["Authorization"];
}
export const UPDATE_CALL_STATE = (state, newCallState) => {
  state.callState = newCallState;
}
export const SESSION_SETTINGS = (state, data) => {
  state.sessionSettings = data;
}