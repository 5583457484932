import axios from "axios";
import {
  required,
  requiredUnless,
  minLength,
  maxLength,
  minValue,
  email,
  helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const { withAsync } = helpers;

export const uwillValidatorMixin = {
  emits: [
    "update:emailValidated",
    "update:firstNameValidated",
    "update:lastNameValidated",
    "update:uniqueIdentifierValidated",
    "update:selectedReasonValidated",
    "update:selectedPresentingProblemValidated",
    "update:callerIdentifiedProblemValidated",
    "update:goalIdentifiedValidated",
    "update:interventionPlanValidated",
    "update:selectedIdeationsValidated",
    "update:selectedRecommendedActionsValidated",
    "update:addressValidated",
    "update:birthdateValidated",
    "update:telephoneValidated",
    "update:riskSynthesisValidated",
    "update:emergencyResponseInformationValidated",
    "update:historicDateValidated",
    "emit-field"
  ],
  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    anonymous: false,
    forceFieldValidation: 0
  }),
  validations: {
    designation: {
      required,
      minLength: minLength(2)
    },
    serviceYears: {
      required,
      minValue: minValue(0)
    },
    password: {
      required,
      minLength: minLength(8),
      complexity: function(value) {
        return /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/.test(
          value
        );
      }
    },
    email: {
      required,
      email,
      isUnique: withAsync(async value => {
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
          return true;
        }

        const resp = await axios({
          method: "post",
          url: "/emailunique",
          data: {
            email: value
          }
        });
        return resp.data.unique;
      }),
      isValidOrganization(value) {
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))
          return true;
        return new Promise(resolve => {
          axios({
            method: "post",
            url: "/validorganization",
            data: {
              email: value,
              invitation_code: this.invitation_code
            }
          }).then(resp => {
            this.$emit("organizations-update", resp.data);
            resolve(resp.data.valid);
          });
        });
      }
    },
    anonymous: { required },
    reasons: { required },
    city: { required },
    zipcode: { required },
    unique_identifier: {},
    address: {},
    address1: { required },
    address2: {},
    country: { required },
    firstName: {
      required: requiredUnless(function() {
        return this.anonymous == true;
      })
    },
    lastName: {
      required: requiredUnless(function() {
        return this.anonymous == true;
      })
    },
    preferredName: { maxLength: maxLength(255) },
    uniqueIdentifier: {
      required,
      minLength: minLength(4)
    },
    birthDate: { required },
    photo: { required },
    bio: { required, maxLength: maxLength(750) },
    introductionEmail: { maxLength: maxLength(750) },
    about: { maxLength: maxLength(750) },
    contact1Name: { required },
    contact1Telephone: {
      required,
      isPhone(value) {
        if (value === "") return true;
        let telephoneRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/im;
        return telephoneRegEx.test(value);
      }
    },
    contact2Name: { required },
    contact2Telephone: {
      required,
      isPhone(value) {
        if (value === "") return true;
        let telephoneRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/im;
        return telephoneRegEx.test(value);
      }
    },
    telephone: {
      required,
      isPhone(value) {
        if (value === "") return true;
        let telephoneRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/im;
        return telephoneRegEx.test(value);
      }
    },
    specialty: {},
    gender: { required },
    graduationDate: { required },
    selectedReason: { required },
    selectedPresentingProblem: { required },
    callerIdentifiedProblem: { required }
  },
  computed: {
    firstNameErrorMessages() {
      this.firstName;
      this.forceFieldValidation;
      return this.requiredErrors("firstName");
    },
    lastNameErrorMessages() {
      this.forceFieldValidation;
      let value = this.requiredErrors("lastName");
      return value;
    },
    userEmailErrorMessages() {
      this.email;
      this.forceFieldValidation;
      return this.userEmailErrors();
    },
    uniqueIdentifierErrorMessages() {
      this.uniqueIdentifier;
      this.forceFieldValidation;
      return this.uniqueIdentifierErrors();
    },
    selectedReasonsErrorMessages() {
      this.selectedReason;
      this.forceFieldValidation;
      return this.requiredErrors("selectedReason");
    },
    selectedPresentingProblemErrorMessages() {
      this.selectedPresentingProblem;
      this.forceFieldValidation;
      return this.requiredErrors("selectedPresentingProblem");
    },
    callerIdentifiedProblemErrorMessages() {
      this.callerIdentifiedProblem;
      this.forceFieldValidation;
      return this.requiredErrors("callerIdentifiedProblem");
    },
    goalIdentifiedErrorMessages() {
      this.goalIdentified;
      this.forceFieldValidation;
      return this.requiredErrors("goalIdentified");
    }
  },
  methods: {
    userEmailErrors() {
      if (this.v$.email != null && !this.v$.email.$dirty) {
        return [];
      }
      let errors = this.requiredErrors("email", false);
      !this.v$.email.email && errors.push("Must be valid email");
      !this.v$.email.isUnique && errors.push("Email address is already in use");
      !this.v$.email.isValidOrganization &&
        errors.push(
          "You are not currently eligible to use Uwill. Make sure you are using your school email address."
        );
      let emitValue = null;
      if (errors.length == 0) {
        emitValue = this.v$.email.$model;
      }
      this.$emit("update:emailValidated", emitValue);
      return errors;
    },
    toCamelCase(string = "") {
      let result = string.replace(/\s+(.)/g, function(match, group) {
        return group.toUpperCase();
      });
      return result;
    },
    toSentenceCase(string = "") {
      const result = string.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    descriptor(label) {
      let val = label.length < 30 ? label : "Field";
      return val;
    },
    maxRequiredErrors(name, final = true, errors = []) {
      errors = this.requiredErrors(name, false);
      return this.maxLengthErrors(name, final, errors);
    },
    maxLengthErrors(name, final = true, errors = []) {
      let label = this.toSentenceCase(name);
      if (this.v$[name] != null && !this.v$[name].$dirty) {
        return errors;
      }

      if (this.v$[name].maxLength.$invalid) {
        errors.push(this.descriptor(label) + " is required");
      }
      let emitString = `update:${name}Validated`;
      let emitValue = null;
      if (final && errors.length == 0) {
        emitValue = this.v$[name].$model;
      }
      this.$emit(emitString, emitValue);
      return errors;
    },
    phoneRequiredErrors(name, final = true, errors = []) {
      errors = this.requiredErrors(name, false, errors);
      return this.telephoneErrors(name, final, errors);
    },
    telephoneErrors(name, final = true, errors = []) {
      // let label = this.toSentenceCase(name);
      if (this.v$[name] != null && !this.v$[name].$dirty) {
        return errors;
      }
      // this.v$[name]?.isPhone.$invalid &&
      // errors.push(this.descriptor(label) + " is not a valid number");
      let emitString = `update:${name}Validated`;
      let emitValue = null;
      if (final && errors.length == 0) {
        emitValue = this.v$[name].$model;
      }
      this.$emit(emitString, emitValue);
      return errors;
    },
    requiredUnlessErrors(name, final = true, errors = [], conditionalField) {
      if (this.$v[conditionalField] == true) {
        return errors;
      } else {
        return this.requiredErrors(name, final, errors);
      }
    },
    requiredErrors(name, final = true, errors = []) {
      let label = this.toSentenceCase(name);
      if (
        !this.v$.$anyDirty ||
        (this.v$[name] != null && this.v$[name].$valid)
      ) {
        return errors;
      }

      var error_message = this.descriptor(label) + " is required";
      if (label.match("Selected") != null) {
        error_message = error_message.replace("Selected ", "");
      } else if (label == "Contact1 Name") {
        error_message = "Contact 1 Name is required";
      } else if (label == "Contact1 Telephone") {
        error_message = "Contact 1 Phone is required";
      } else if (label == "Contact2 Name") {
        error_message = "Contact 2 Name is required";
      } else if (label == "Contact2 Telephone") {
        error_message = "Contact 2 Phone is required";
      }

      if (label == "Next Steps")
        error_message = "Intervention / Plan is required";

      if (this.v$[name].required.$invalid && this.v$[name].$anyDirty) {
        errors.push(error_message);
      }
      let emitValue = null;
      if (final && errors.length == 0) {
        emitValue = this.v$[name].$model;
      }
      this.$emit(`update:${name}Validated`, emitValue);
      return errors;
    }
  }
};
