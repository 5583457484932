import axios from "axios";

export const uwillUserManagementMixin = {
  methods: {
    setupUser: function() {
      if (this.$store.getters.isLoggedIn) {
        this.firstName = this.user.firstName;
        this.lastName = this.user.lastName;
        this.preferredName = this.user.preferredName;
        this.birthDate = this.user.birthdate;
        this.email = this.user.email;
        this.uniqueIdentifier = this.user.uniqueIdentifier;
        this.gender = this.user.gender;
        this.telephone = this.user.profile.telephone;
        this.ethnicity = this.user.ethnicity;
        this.timezone = this.user.timezone;
        if (this.user.profile) {
          this.address1 = this.user.profile.address1;
          this.address2 = this.user.profile.address2;
          this.city = this.user.profile.city;
          this.zipcode = this.user.profile.zipcode;
          this.state = this.user.profile.state;
          this.country = this.user.profile.country;
          this.pronouns = this.user.profile.pronouns;
          this.reasons = this.user.profile.reasons;
          this.focusAreas = this.user.profile.focusAreas;
        }
        if (this.user.provider || this.user.instructor) {
          this.bio = this.user.profile.bio;
          this.introductionEmail = this.user.profile.introductionEmail;
          this.specialty = this.user.profile.roleTherapistSpecialty;
          this.designation = this.user.profile.roleTherapistDesignation;
          this.internationallyAvailable = this.user.profile.internationallyAvailable;
          this.serviceYears = this.user.profile.roleTherapistServiceYears;
          if (this.user.instructor) {
            this.specialty = this.user.profile.roleInstructorSpecialty;
            this.designation = this.user.profile.roleInstructorDesignation;
            this.serviceYears = this.user.profile.roleInstructorServiceYears;
          }
          this.state = this.user.licensedStates;
          this.languages = this.user.languages;
        } else {
          this.about = this.user.profile.about;
          this.contact1Name = this.user.profile.contact1Name;
          this.contact2Name = this.user.profile.contact2Name;
          this.contact1Telephone = this.user.profile.contact1Telephone;
          this.contact2Telephone = this.user.profile.contact2Telephone;
          if (this.education) {
            this.graduationDate = this.user.profile.graduationDate;
            // this.school_state = this.user.profile.schoolState;
          }
        }
      }
    }
  }
};
export const uwillProfileWrapperMixin = {
  data: () => ({
    timezone: "America/New_York",
    internationallyAvailable: false,
    genders: ["Female", "Male", "Non-Binary", "Prefer not to say"],
    reason_choices: [],
    pronoun_choices: [],
    focus_area_choices: [],
    language_choices: [],
    ethnicity_choices: [],
    reasons: null,
    focusAreas: null,
    languages: null,
    ethnicity: null,
    pronouns: "No Preference",
    contact1Name: "",
    contact1Telephone: "",
    contact2Name: "",
    contact2Telephone: "",
    email: "",
    uniqueIdentifier: "",
    password: "",
    firstName: "",
    lastName: "",
    preferredName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    school_state: "",
    country: "",
    photo: null,
    bio: "",
    introductionEmail: "",
    about: "",
    serviceYears: "",
    telephone: "",
    specialty: "",
    designation: "",
    gender: "",
    birthDate: null,
    graduationDate: null,
    basicFields: ["firstName", "lastName", "email", "birthDate", "telephone"],
    instructorFields: ["firstName", "lastName", "email"],
    contractorFields: ["firstName", "lastName", "email"],
    additionalProviderFields: [
      "country",
      "serviceYears",
      "designation",
      "introductionEmail"
    ],
    additionalStudentFields: [
      "address1",
      "city",
      "country",
      "zipcode",
      "contact1Telephone",
      "contact1Name",
      "contact2Telephone",
      "contact2Name"
    ],
    additionalEmployeeFields: [
      "address1",
      "city",
      "country",
      "zipcode",
      "contact1Telephone",
      "contact1Name",
      "contact2Telephone",
      "contact2Name"
    ],
    loading: false,
    loader: null
  }),
  mounted: function() {
    if (this.user) {
      this.setupUser();
    }
    axios({
      method: "get",
      url: "/fieldvalues"
    }).then(resp => {
      const ethnicities = resp.data.ethnicities;
      ethnicities.unshift("Prefer not to say");
      // Polish the text but not value (so API doesn't get confused)
      this.ethnicity_choices = ethnicities.map(ethnicity => {
        let ethnicityText = ethnicity.replace("Or", "or"); // Black or African American
        return { value: ethnicity, text: ethnicityText };
      });

      this.reason_choices = resp.data.reasons;
      this.pronoun_choices = resp.data.pronouns;
      this.focus_area_choices = resp.data.focus_areas;
      this.language_choices = resp.data.languages;
    });
  },
  props: {
    mode: {
      default: "create",
      type: String
    }
  },
  computed: {
    user(){
      this.$store.state.user;
    },
    basicRegistrationFormValid() {
      if (this.basicFormValid) {
        if (
          !this.isProvider &&
          !this.isInstructor &&
          this.organization_choice &&
          this.organization == null
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    basicFormProblems() {
      let problems = [];
      for (let i = 0; i < this.basicFields.length; i++) {
        let field = this.basicFields[i];
        let value = this[field];
        if (value == null || value === "") {
          if (field == "uniqueIdentifier") {
            problems.push(this.$methods.getStudentWord() + " ID");
          } else {
            problems.push(this.toSentenceCase(field));
          }
        } else {
          // Else if text present (currently only birthDate)
          let pushBirthDateProblem = false;

          if (field == "telephone") {
            const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-.(\s]*(\d{1,4})[-.)\s]*(\d{3,5})[-. ]*(\d{3,6})$/;
            if (phoneRegex.test(value) == false) problems.push(this.toSentenceCase(field));
          }

          if (field == "birthDate") {
            // Example: 2000-01-15
            // Year should have exactly 4 characters
            // Month and day should each have either 1 or 2 characters
            const birthDateRegex = /^\d{4}-\d{1,2}-\d{1,2}$/;
            if (!birthDateRegex.test(value)) pushBirthDateProblem = true;

            // At this point, birthDate has 2 dashes and only numbers

            // Split birth date to test for min and max of year, month, and day
            const splitBirthDate = value.split("-");
            const birthYear = parseInt(splitBirthDate[0]);
            const birthMonth = parseInt(splitBirthDate[1]);
            const birthDay = parseInt(splitBirthDate[2]);

            // Year out of bounds
            const currentYear = new Date().getFullYear();
            if (birthYear < 1920 || birthYear > currentYear) pushBirthDateProblem = true;

            // Month out of bounds
            if (birthMonth < 1 || birthMonth > 12) pushBirthDateProblem = true;

            // Day out of bounds
            if (birthDay < 1 || birthDay > 31) pushBirthDateProblem = true;

            if (pushBirthDateProblem) problems.push(this.toSentenceCase(field));
          }
        }
      }
      if (this.mode == "create") {
        if (this.password == null || this.password === "") {
          problems.push("Password");
        }
      } else {
        if (
          this.user.requireUniqueIdentifier &&
          (this.uniqueIdentifier == null || this.uniqueIdentifier === "")
        ) {
          problems.push(this.$methods.getStudentWord() + " ID");
        }
      }
      if (this.isProvider || this.isInstructor) {
        if (this.gender == null) {
          problems.push("Gender");
        }
      }
      return problems;
    },
    instructorFormProblems() {
      let problems = [];
      for (let i = 0; i < this.instructorFields.length; i++) {
        let field = this.instructorFields[i];
        let value = this[field];
        if (value == null || value === "") {
          problems.push(this.toSentenceCase(field));
        }
      }
      return problems;
    },
    contractorFormProblems() {
      let problems = [];
      for (let i = 0; i < this.contractorFields.length; i++) {
        let field = this.contractorFields[i];
        let value = this[field];
        if (value == null || value === "") {
          problems.push(this.toSentenceCase(field));
        }
      }
      return problems;
    },
    profileValid() {
      if (this.isInstructor) {
        return this.instructorFormValid;
      } else if (this.isContractor) {
        return this.contractorFormValid;
      } else {
        return this.basicFormValid && this.additionalFormValid;
      }
    },
    profileProblems() {
      if (this.isInstructor) {
        return [...this.instructorFormProblems];
      } else if (this.iscontractor) {
        return [...this.contractorFormProblems];
      } else {
        return [...this.basicFormProblems, ...this.additionalFormProblems];
      }
    },
    basicFormValid() {
      return this.basicFormProblems.length == 0;
    },
    instructorFormValid() {
      return this.instructorFormProblems.length == 0;
    },
    contractorFormValid() {
      return this.contractorFormProblems.length == 0;
    },
    additionalFormProblems() {
      let fieldsToValidate = this.isProvider
        ? this.additionalProviderFields
        : this.education
        ? this.additionalStudentFields
        : this.additionalEmployeeFields;
      let problems = [];
      for (let i = 0; i < fieldsToValidate.length; i++) {
        let field = fieldsToValidate[i];
        let value = this[field];
        if (value == null || value === "") {
          problems.push(this.toSentenceCase(field));
        } else {
          if (field == "contact1Telephone" || field == "contact2Telephone") {
            const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-.(\s]*(\d{1,4})[-.)\s]*(\d{3,5})[-. ]*(\d{3,6})$/;
            if (phoneRegex.test(value) == false) problems.push(this.toSentenceCase(field));
          }
        }
      }
      if (this.country == "United States") {
        if (this.isProvider && this.state.length == 0) {
          problems.push("Country");
        } else if (!this.isProvider) {
          if (!this.state) {
            problems.push("State");
          }
        }
      }
      if (this.mode == "create" && this.isProvider) {
        if (this.photo == null) {
          problems.push("Photo");
        }
      }
      return problems;
    },
    additionalFormValid() {
      return this.additionalFormProblems.length == 0;
    },
    threePerRowColumns: function() {
      return this.mode == "create" ? 12 : 4;
    },
    twoPerRowColumns: function() {
      return this.mode == "create" ? 12 : 6;
    }
  },
  methods: {
    // Currently, string nulls can be sent to and saved in API
    // This is triggering unique phone number errors and causing a wonky experience
    // TODO: Fix string nulls and remove string null conditionals below
    contactPhoneDuplicated(contactNum) {
      return this.userTelephone == contactNum
        && (contactNum !== "" && contactNum !== "null");
    },
    emergencyPhoneDuplicated(contactNum) {
      return (
        this.contact1Telephone == this.contact2Telephone
          && (contactNum !== "" && contactNum !== "null")
      );
    },
    duplicatePhoneErrors(value) {
      let errors = [];
      if (this.contactPhoneDuplicated(value)) {
        errors.push(
          "You cannot use your primary contact number as an emergency number"
        );
      }
      if (this.emergencyPhoneDuplicated(value)) {
        errors.push("Your emergency contact numbers cannot be the same");
      }
      return errors;
    },
    updatePronouns(event) {
      this.$emit("update:pronounsValidated", event);
    },
    toSentenceCase(string = "") {
      const result = string.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    finish() {
      if (!this.tos && this.mode == "create") {
        window.Bus.$emit(
          "error-message",
          "Before you can finish your registration, please read and agree to the Uwill Terms and Conditions of Use",
          "Please fix the problems with your registration",
          700
        );
      } else {
        let timezone = this.timezone;
        if (typeof timezone !== "string") {
          timezone = this.$methods.encodedTimezone()
        }
        this.loading = true;
        this.loader = "loading";
        let gso;
        if (this.$route.query !== null) {
          gso = this.$route.query.gso;
        }

        // Bundle up the data to submit
        let form_attributes = {
          // school_state: this.school_state,
          gso: gso,
          saml: this.samlMode,
          timezone: timezone,
          organization_lock: this.gso,
          address1: this.address1,
          address2: this.address2,
          unique_identifier: this.uniqueIdentifier,
          city: this.city,
          zipcode: this.zipcode,
          focus_areas: this.focusAreas,
          state: this.state,
          country: this.country,
          bio: this.bio,
          about: this.about,
          introduction_email: this.introductionEmail,
          gender: this.gender,
          telephone: this.telephone,
          graduation_date: this.graduationDate,
          first_name: this.firstName,
          last_name: this.lastName,
          preferred_name: this.preferredName,
          birthdate: this.birthDate,
          classification: this.classification,
          reasons: this.reasons,
          pronouns: this.pronouns,
          contact_1_name: this.contact1Name,
          contact_2_name: this.contact2Name,
          contact_1_telephone: this.contact1Telephone,
          contact_2_telephone: this.contact2Telephone,
          languages: this.languages,
          ethnicity: this.ethnicity,
          organization: this.organization,
          organization_choice: this.organization_choice,
          register_as_student: this.register_as_student,
          internationally_available: this.internationallyAvailable
        };

        if (this.isInstructor) {
          form_attributes["role_instructor_designation"] = this.designation;
          form_attributes["role_instructor_specialty"] = this.specialty;
          form_attributes["role_instructor_service_years"] = this.serviceYears;
        } else if (this.isProvider) {
          form_attributes["role_therapist_designation"] = this.designation;
          form_attributes["role_therapist_specialty"] = this.specialty;
          form_attributes["role_therapist_service_years"] = this.serviceYears;
        }

        if (
          this.$route.params.token ||
          (this.$route.query !== null && this.$route.query.token)
        ) {
          form_attributes["organization_token"] =
            this.$route.params.token || this.$route.query.token;
          if (this.$route.query.k) {
            form_attributes["organization_lock"] = this.$route.query.k;
          }
        }

        if (this.photo) {
          form_attributes["photo"] = this.photo;
        }
        let formData = new FormData();
        Object.entries(form_attributes).forEach(([key, value]) =>
          formData.append(key, value)
        );
        if (this.mode == "create") {
          form_attributes = {
            email: this.email,
            password: this.password
          };
          let code = this.$store.getters.invitation_code;
          if (code) {
            form_attributes["invitation_code"] = code.code;
          }
          Object.entries(form_attributes).forEach(([key, value]) =>
            formData.append(key, value)
          );
          this.$store
            .dispatch("register", formData)
            .then(() => {
              this.$methods.setUserOptOutFor("uwill_opt_out_availability", 7);
              this.$router.push("/dashboard");
            })
            .catch(err => {
              window.Bus.$emit("error-message", Object.values(err));
              this.errors = err;
            });
        } else {
          axios
            .put("/user", formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(resp => {
              window.Bus.$emit("flash-message", {
                text: resp.data.message
              });
              this.$store.dispatch("get_user");
            });
        }
      }
    }
  }
};
