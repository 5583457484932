import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import * as actions from "./actions";
import * as mutations from "./mutations";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export default createStore({
  plugins: [vuexLocal.plugin],
  state: {
    status: "",
    token: localStorage.getItem("uwillToken") || null,
    isConfirmed: true,
    isIncomplete: false,
    user: {},
    isDashboard: false,
    isCollaborate: false,
    isOperator: false,
    isChangeover: false,
    therapists: null,
    invitation_code: null,
    needsTFA: false,
    callState: {
      state: "",
      stateClass: "",
      viewHeading: "",
      viewIcon: ""
    },
    sessionSettings: {}
  },
  mutations: mutations,
  actions: actions,
  getters: {
    isLoggedIn: state => !!state.token,
    isIncomplete: state => state.isIncomplete,
    authStatus: state => state.status,
    isConfirmed: state => state.isConfirmed,
    isChangeover: state => state.isChangeover,
    isDashboard: state => state.isDashboard,
    isCollaborate: state => state.isCollaborate,
    isOperator: state => state.isOperator,
    therapists: state => state.therapists,
    providers: state => state.providers,
    invitation_code: state => state.invitation_code,
    needsTFA: state => state.needsTFA,
    mfaRequired: state => state.mfaRequired
  },
  modules: {}
});
