<!-- Note that we're using error here -->
<!-- Because that's the only prop that reliably generates ADA announcement -->
<template>
  <div>
    <vue3-snackbar
      bottom
      shadow
      duration="5000"
      error="#587B84"
      @added="snackAdded"
      @cleared="snackCleared"
      @removed="snackCleared"
      @dismissed="snackCleared"
    >
    <!-- @removed="nextItem"
      @dismissed="nextItem" -->

      <template #message-action="{ message }">
        <v-btn
          v-if="message.action == 'supplemental'"
          class="flashbar-more-btn"
          @click="showRestrictionDialog = true"
        >
          More
        </v-btn>
      </template>
      <template #message-icon="{ message }">
        <v-icon class="icon"> fa-solid {{ icon_string(message) }} </v-icon>
      </template>
    </vue3-snackbar>
    <RestrictionTerms
      v-model="showRestrictionDialog"
      :therapist="isTherapist"
      :supplemental="isSupplemental"
      :student="isStudent"
    />
  </div>
</template>

<script>
import RestrictionTerms from "./RestrictionTerms.vue";

export default {
  name: "FlashMessage",
  components: {
    RestrictionTerms
  },
  data: () => ({
    message_type: "",
    currentMessage: "",
    isDisplayingMessage: false,
    fullMessage: null,
    isExpanded: false,
    showRestrictionDialog: false,
    isTherapist: false,
    isSupplemental: false,
    isStudent: false,
    isExistingAppointment: false,
    isChooseAfter: false
    //queuedMessages: []
  }),
  watch: {
    // Per Pedrelli, we're deprecating the message queue.
    // Leaving this in case we want to implement priority messaging;
    //isDisplayingMessage(val){
      // console.log(`isdisplaying ${val}`)
    //   if(val == true || this.queuedMessages.length == 0) return;

    //   this.displayMessage(this.queuedMessages[0]);
    //   this.queuedMessages.splice(0,1);
    // }
    // continues...
  },
  mounted: function() {

    window.Bus.$on("flash-message", message => {
      // continuing queue deprecation from above
      // console.log('message event recieved');

      // if (this.isDisplayingMessage && message.message_type != "error"){
      //   this.queuedMessages.push(message);
      // }else{
      this.displayMessage(message);
      // }
    });
  },
  methods: {
    snackAdded: function(){
      this.isDisplayingMessage = true;
    },
    snackCleared: function(){
      this.isDisplayingMessage = false;
    },
    displayMessage: function(message){
      this.fullMessage = message;
      this.currentMessage = Array.isArray(message.text)
        ? message.text[0]
        : message.text;
      this.isExpanded = false;
      this.isSupplemental = message.supplemental;
      this.isTherapist = message.therapist;
      this.isStudent = message.student;
      this.isExistingAppointment = message.existing_appointment;
      this.isChooseAfter = message.choose_after;
      this.$snackbar.add({
        type: "error",
        text: this.currentMessage,
        action: message.action
      });

    },
    icon_string: function(message) {
      // TODO: We need an ADA pass to ensure other message types now do
      // screen reader events. Delete uncommented code, and uncomment the below
      // let icons = {
      //   success: "fa-circle-check",
      //   error: "fa-hexagon-exclamation",
      //   info: "fa-circle-exclamation",
      //   warning: "fa-triangle-exclamation"
      // };
      //return icons[message.type];


      console.log(message);

      return "fa-circle-exclamation";
    },
    showRestrictionTerms() {
      this.showRestrictionDialog = true;
    },
    closeRestrictionDialog() {
      this.showRestrictionDialog = false;
    }
  }
};
</script>
<style>
.vue3-snackbar-message-close {
  margin-left: 8px !important;
}
.vue3-snackbar-message-icon {
  margin-right: 8px;
}
.vue3-snackbar-message-icon .v-icon {
  width: 18px;
  height: 16px;
}
.vue3-snackbar-message-wrapper{
  width:100%
}
.vue3-snackbar-message-content {
  flex-flow: row !important;
  width: 100% !important;
}
#vue3-snackbar--container {
  width: 80% !important;
  max-width: 450px;
}
.vue3-snackbar-message-action {
  margin: auto 8px;
  width: fit-content !important;
}
.flashbar-more-btn {
  background: transparent;
  border: 1px solid white;
  text-transform: none;
  padding: 6px 12px;
}

.flashbar-more-btn .v-btn__content {
  font-weight: 500;
  font-family: "Roboto";
}
</style>
