import axios from "axios";
export const login = ({ dispatch, commit }, user) => {
  return new Promise((resolve, reject) => {
    commit("AUTH_REQUEST");
    axios({
      method: "post",
      url: "/users/login",
      data: {
        user: user
      }
    })
      .then(resp => {
        if (resp.data.user && !resp.data.errors) {
          dispatch("setup_user", resp.data);
          window.Bus.$emit("flash-message", {
            text: "You've successfully logged in"
          });
          resolve(resp);
        } else if (resp.data.idp_url) {
          window.location = resp.data.idp_url;
        } else {
          reject(resp.data.errors);
        }
      })
      .catch(err => {
        commit("AUTH_ERROR");
        localStorage.removeItem("uwillToken");
        reject(err.response.data.errors);
      });
  });
};
export const setup_user = ({ commit }, data) => {
  const token = data.user.token;
  const user = data.user;
  localStorage.setItem("uwillToken", token);
  if (data.impersonating) {
    localStorage.setItem("uwillImpersonation", true);
  }
  axios.defaults.headers.common["Authorization"] = token;
  commit("AUTH_SUCCESS", {
    token: token,
    user: user
  });
  if (data.mfaRequired) {
    commit("MFA_REQUIRED", true);
  } else if (user.tfaSecret) {
    commit("TFA", user.tfaSecret);
  } else {
    if (user.collaborateAdmin) {
      commit("COLLABORATE");
    } else if (user.operator) {
      commit("OPERATOR");
    } else if (user.changeover) {
      commit("CHANGEOVER");
    } else if (user.profileIncomplete) {
      commit("INCOMPLETE");
    } else {
      commit("DASHBOARD");
    }
  }
};
export const logout = ({ commit }) => {
  return new Promise(() => {
    axios({
      method: "post",
      url: "/users/logout"
    }).finally(() => {
      // Use finally (instead of then and catch) to log out even during an error
      commit("LOGOUT");
      window.Bus.$emit("flash-message", {
        text: "You have successfully logged out."
      });
      location.href = "/login";
    });
  });
};
export const get_user = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: "/user"
    })
      .then(resp => {
        if (resp.data.user && !resp.data.errors) {
          const user = resp.data.user;
          commit("USER_REQUEST_SUCCESS", { user: user });
          if (resp.data.mfaRequired) {
            commit("MFA_REQUIRED", true);
          }
          if (user.profileIncomplete) {
            commit("INCOMPLETE");
          }
          resolve(resp);
        } else {
          reject(resp.data.errors);
        }
      })
      .catch(err => {
        localStorage.removeItem("uwillToken");
        commit("USER_REQUEST_ERROR");
        if (err.response.status == 401) {
          reject(err.response);
        } else {
          reject(err.response.data.errors);
        }
      });
  });
};
export const get_session_settings = ({ commit, dispatch }) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/uisessionsettings")
      .then(resp => {
        commit("SESSION_SETTINGS", resp.data);

        // Check if resp.data does not return {}
        if (Object.keys(resp.data).length > 0) {
          const isActive = resp.data.active || false,
            timeoutInactive = resp.data.session_timeout_inactivity * 60000, // in ms
            timeoutWarningMessage = resp.data.session_timeout_warning_message,
            warningInterval = resp.data.session_timeout_warning_interval * 1000; // in ms
          let activityTimeout, warningTimeout;

          const logoutUser = () => {
            localStorage.setItem("uwill_session_timed_out", true);
            dispatch("logout");
          };

          const showWarning = () => {
            const warningMessage = timeoutWarningMessage.replace(
              "[X]",
              warningInterval / 1000
            );
            window.Bus.$emit(
              "error-message",
              `${warningMessage} Click OK to stay logged in`,
              "Are you still there?"
            );
            warningTimeout = setTimeout(logoutUser, warningInterval);
          };

          const resetActivityTimeout = () => {
            clearTimeout(activityTimeout);
            clearTimeout(warningTimeout);

            activityTimeout = setTimeout(
              showWarning,
              timeoutInactive - warningInterval
            );
          };

          const startActivityListeners = () => {
            window.addEventListener("scroll", resetActivityTimeout);
            window.addEventListener("click", resetActivityTimeout);
            window.addEventListener("keydown", resetActivityTimeout);
          };

          if (isActive && timeoutInactive) {
            startActivityListeners();

            activityTimeout = setTimeout(
              showWarning,
              timeoutInactive - warningInterval
            );
          }
        }
        resolve(resp);
      })
      .catch(err => {
        console.log("session_settings err", err);
        reject(err.response.data.errors);
      });
  });
};
export const register = ({ commit }, formData) => {
  return new Promise((resolve, reject) => {
    commit("REGISTER_REQUEST");
    axios
      .post("/users", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(resp => {
        if (resp.data.user && !resp.data.errors) {
          const token = resp.data.user.token;
          const user = resp.data.user;
          localStorage.setItem("uwillToken", token);
          axios.defaults.headers.common["Authorization"] = token;
          commit("REGISTER_SUCCESS", { token: token, user: user });
          window.Bus.$emit("flash-message", {
            text: "Welcome to Uwill!"
          });
          resolve(resp);
        } else {
          reject(resp.data.errors);
        }
      })
      .catch(err => {
        commit("REGISTER_ERROR");
        localStorage.removeItem("uwillToken");
        if (err.response) {
          reject(err.response.data.errors);
        }
      });
  });
};
export const toggle_vacation = ({ commit }) => {
  commit("TOGGLE_VACATION");
};
export const update_timezone = ({ commit }, data) => {
  commit("UPDATE_TIMEZONE", data);
};
export const set_therapists = ({ commit }, therapists) => {
  commit("THERAPIST_RESULTS", { therapists: therapists });
};
export const set_providers = ({ commit }, providers) => {
  commit("PROVIDER_RESULTS", { providers: providers });
};
export const set_invitation_code = ({ commit }, data) => {
  commit("invitation_code", data);
};
export const updateCallState = ({ commit }, newCallState) => {
  commit("UPDATE_CALL_STATE", newCallState);
};
