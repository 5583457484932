<template>
  <v-app>
    <a href="#main" class="skip-to-main-content-link">Skip to main content</a>
    <v-system-bar v-if="isStaging" color="orange" height="30">
      <div class="text-center font-weight-bold" style="width:100%;color:#000;">
        You are viewing the Uwill Test Application
      </div>
    </v-system-bar>
    <v-system-bar v-if="isDemo" color="#6667AB" height="30">
      <div class="text-center font-weight-bold" style="width:100%;color:#000;">
        You are viewing the Uwill Demo Application
      </div>
    </v-system-bar>
    <v-app-bar
      v-if="$route.name != 'Demo' && $route.name != 'EventRegistration'"
      class="uwill app-bar"
      border
      elevation="0"
    >
      <a href="/" alt="Uwill">
        <div class="d-flex align-center brand ml-3">
          <img class="logo" alt="Uwill Home" :src="images.uwill" />
        </div>
      </a>
      <v-spacer></v-spacer>
      <v-chip
        v-if="is_impersonating"
        class="ma-2"
        color="#ff0000"
        size="large"
        link
        @click="$methods.logout"
      >
        You are impersonating another user
      </v-chip>
      <span v-if="isLoggedIn" class="welcome">
        Welcome, {{ user.firstName }}
      </span>
      <span v-else>
        <a href="/login" class="mr-3">Log In</a>
      </span>
      <v-menu
        v-model="account_menu"
        origin="top left"
        location="bottom"
        :persistent="false"
        close-on-content-click
        content-class="account-menu"
        min-width="225px"
      >
        <template #activator="{ props }">
          <v-btn
            icon v-bind="props"
            title="Account Information"
            aria-expanded="undefined"
          >
            <v-icon class="uwill-primary">fa-solid fa-circle-user</v-icon>
          </v-btn>
        </template>
        <v-list role="menu" aria-label="Main Menu">
          <v-list-item @click="$methods.home" role="menuitem">Home</v-list-item>
          <v-list-item
            v-if="user && user.provider"
            target="NEW"
            href="https://uwill.com/wp-content/uploads/2023/08/uwill_counselors_quick_start_guide_2023_FNL.pdf"
            role="menuitem"
            style="color: rgba(0, 0, 0, 0.87);"
          >
            {{ $filters.capitalize($t("text.provider")) }} Guide
          </v-list-item>
          <v-list-item
            v-if="user && (isCollaborate || (!user.provider && user.education))"
            target="NEW"
            href="https://uwill.com/wp-content/uploads/2020/10/uwill_student_quick_start_guide_10.20.pdf"
            role="menuitem"
            style="color: rgba(0, 0, 0, 0.87);"
          >
            {{ $filters.capitalize($methods.getStudentWord()) }} Guide
          </v-list-item>
          <v-list-item
            v-else
            target="NEW"
            href="https://uwill.com/wp-content/uploads/2020/10/uwill_student_quick_start_guide_10.20.pdf"
            role="menuitem"
            style="color: rgba(0, 0, 0, 0.87);"
          >
            Guide
          </v-list-item>
          <v-list-item v-if="!isCollaborate" @click="this.$refs.promo_code_dialog.openDialog();">
            Redeem A Promo Code
          </v-list-item>
          <v-list-item
            v-if="
              user &&
                (user.provider || user.contractor) &&
                user.payoutSetupComplete
            "
            target="NEW"
            @click="$methods.getStripeLink()"
            role="menuitem"
          >
            Manage your Stripe Payment Information
          </v-list-item>
          <v-list-item
            v-if="user && !isCollaborate && !isOperator && !user.provider"
            href="/purchasecredits"
            role="menuitem"
            style="color: rgba(0, 0, 0, 0.87);"
          >
            Purchase Credits
          </v-list-item>
          <v-divider v-if="isLoggedIn"></v-divider>
          <v-list-item
            v-if="
              user && isLoggedIn && user.tfaSecret && !$store.getters.needsTFA
            "
            @click="$methods.remove2FA()"
            role="menuitem"
          >
            Remove Two-Factor Authentication
          </v-list-item>
          <v-list-item
            v-else-if="user && isLoggedIn && !user.tfaSecret"
            href="/2fa"
            role="menuitem"
            style="color: rgba(0, 0, 0, 0.87);"
          >
            Set Up Two-Factor Authentication
          </v-list-item>
          <v-list-item v-if="!isLoggedIn" @click="login">Log in</v-list-item>
          <v-list-item v-else @click="logout">Log out</v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <PromoCode
      v-if="!isCollaborate"
      ref="promo_code_dialog"
      role="menuitem"
      @close-menu="$methods.closeMenu"
    ></PromoCode>

    <v-main class="uwill-body" role="main" id="main">
      <portal-target name="nav-items" />
      <flash-message />
      <show-error></show-error>
      <router-view
        v-if="
          (!isLoggedIn ||
            isConfirmed ||
            isConfirming ||
            isGuardianConsenting) &&
            !isIncomplete
        "
        class="router-view"
      ></router-view>
      <CompleteProfile
        v-else-if="isIncomplete"
        :user-telephone="user.profile.telephone"
        :user-birthdate="user.birthdate"
      ></CompleteProfile>
      <Confirmation v-else></Confirmation>
    </v-main>

    <v-footer>
      <v-spacer></v-spacer>
      <v-col class="text-center text-caption" cols="12">
        &copy; {{ new Date().getFullYear() }} <strong>Uwill, Inc.</strong> |
        <a
          href="/doc/privacy"
          class="font-weight-bold"
          alt="Privacy Policy"
          >Privacy Policy</a
        >
        |
        <a
          href="/doc/terms"
          class="font-weight-bold"
          alt="Terms and Conditions of Use"
          >Terms and Conditions of Use</a
        >
        |
        <strong>
          For assistance, email us at
          <a
            href="mailto:support@uwill.com"
            class="font-weight-bold"
            alt="Support Email Address"
            >support@uwill.com</a
          >
        </strong>
      </v-col>
    </v-footer>
  </v-app>
</template>
<script>
import Confirmation from "@/components/Confirmation.vue";
import CompleteProfile from "@/components/CompleteProfile.vue";
import PromoCode from "@/components/PromoCode.vue";
import FlashMessage from "@/components/FlashMessage.vue";

export default {
  name: "App",
  components: {
    Confirmation,
    PromoCode,
    CompleteProfile,
    FlashMessage
  },
  data: () => ({
    metaInfo: {
      title: "Uwill",
      // all titles will be injected into this template
      titleTemplate: "%s | Uwill"
    },
    account_menu: false,
    images: {
      uwill: require("@/assets/images/uwill.png")
    }
  }),
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    isConfirmed: function() {
      return this.$store.state.user.confirmed;
    },
    isDashboard: function() {
      return this.$store.getters.isDashboard;
    },
    isConfirming: function() {
      return this.$route.path.indexOf("/confirm/") !== -1;
    },
    isIncomplete: function() {
      return this.$store.state.user.profileIncomplete;
    },
    isGuardianConsenting: function() {
      return this.$route.path.indexOf("/guardianconsent/") !== -1;
    },
    isCollaborate: function() {
      return this.$store.getters.isCollaborate;
    },
    isOperator: function() {
      return this.$store.getters.isOperator;
    },
    user: function() {
      return this.$store.state.user;
    },
    is_impersonating: function() {
      return localStorage.getItem("uwillImpersonation") == "true";
    },
    isStaging: function() {
      return process.env.NODE_ENV === "staging";
    },
    isDemo: function() {
      return process.env.NODE_ENV === "demo";
    }
  },
  watch: {
    isLoggedIn: function(newVal) {
      if (newVal) {
        this.$store.dispatch("get_session_settings");
      }
    },
    snackbar: function(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.snackbarContent.focus(); // Move focus to the snackbar
        });
      }
    }
  },
  created: function() {
    // TODO: Uncomment and resolve Vue 3 interceptors issue
    // this.$http.interceptors.response.use(undefined, function(err) {
    //   return new Promise(function() {
    //     if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
    //       this.$store.dispatch("logout");
    //     }
    //     throw err;
    //   });
    // });

    if (this.isLoggedIn) {
      this.$store.dispatch("get_session_settings");
    }
  },
  beforeCreate() {
    this.$store.commit("DASHBOARD");
  },
  mounted: function() {
    if (process.env.NODE_ENV === "staging") {
      let auth = localStorage.getItem("uwill_staging_authorized");
      var redirect;
      if (parseInt(localStorage.getItem("uwill_staging_authorized")) > 0) {
        let now = new Date();
        let permitted = auth > now.valueOf();
        if (permitted) {
          redirect = false;
        } else {
          redirect = true;
          localStorage.removeItem("uwill_staging_authorized");
        }
      } else {
        redirect = auth == "true" ? false : true;
      }
      if (redirect) {
        this.$router.push("/doc/staging");
      }
    } else {
      if (this.isLoggedIn) {
        this.$amplitude.getInstance().setUserId(this.$store.state.user.id);
        this.$amplitude.getInstance().logEvent("Page loaded", {
          url: document.location.pathname
        });
        let organizationNames = this.$store.state.user.organizationNames;
        if (!organizationNames) {
          organizationNames = "";
        }
        let identify = new this.$amplitude.Identify()
          .set("organizations", organizationNames)
          .setOnce("provider", this.$store.state.user.provider);
        this.$amplitude.getInstance().identify(identify);
      }
    }
  },
  methods: {
    logout: function() {
      this.closeMenu();
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    closeMenu: function() {
      this.account_menu = false;
    },
    login() {
      this.$router.push("/login");
    }
  }
};
</script>
