<template>
  <v-row v-if="!isProvider">
    <v-col v-if="mode == 'create'" cols="12">
      <div class="text-subtitle-1">
        Emergency Contacts
      </div>
      <div class="text-subtitle-2">
        Please provide 2 emergency contacts.
      </div>
    </v-col>
    <v-col :cols="mode == 'create' ? 12 : 6">
      <v-text-field
        id="profile-contact-one-name"
        v-model="contact1Name"
        label="Contact 1 Name"
        aria-label="Contact 1 Name"
        prepend-icon="fa-light fa-user"
        type="text"
        required
        :error-messages="contact1NameErrorMessages"
        persistent-hint
        hint="Required - Must be an adult who is 18 years of age or older"
        @update:model-value="$methods.updateField(contact1Name, $event)"
        @blur="touchAllContactFields('contact1Name')"
      />
    </v-col>
    <v-col :cols="mode == 'create' ? 12 : 6">
      <v-text-field
        id="profile-contact-one-phone"
        v-model="contact1Telephone"
        label="Contact 1 Telephone Number"
        aria-label="Contact 1 Telephone Number"
        prepend-icon="fa-light fa-mobile-button"
        type="number"
        required
        hint="Required"
        persistent-hint
        :error-messages="contact1TelephoneErrorMessages"
        @update:model-value="$methods.updateField(contact1Telephone, $event)"
        @blur="touchAllContactFields('contact1Telephone')"
      />
    </v-col>
    <v-col :cols="mode == 'create' ? 12 : 6">
      <v-text-field
        id="profile-contact-two-name"
        v-model="contact2Name"
        label="Contact 2 Name"
        aria-label="Contact 2 Name"
        prepend-icon="fa-light fa-user"
        type="text"
        required
        :error-messages="contact2NameErrorMessages"
        persistent-hint
        hint="Required - Must be an adult who is 18 years of age or older"
        @update:model-value="$methods.updateField(contact2Name, $event)"
        @blur="touchAllContactFields('contact2Name')"
      />
    </v-col>
    <v-col :cols="mode == 'create' ? 12 : 6">
      <v-text-field
        id="profile-contact-two-phone"
        v-model="contact2Telephone"
        label="Contact 2 Telephone Number"
        aria-label="Contact 2 Telephone Number"
        prepend-icon="fa-light fa-mobile-button"
        type="number"
        required
        hint="Required"
        persistent-hint
        :error-messages="contact2TelephoneErrorMessages"
        @update:model-value="$methods.updateField(contact2Telephone, $event)"
        @blur="touchAllContactFields('contact2Telephone')"
        @keyup.enter="additionalFormValid ? nextStep(4) : null"
      />
    </v-col>
  </v-row>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { uwillProfileCategoryMixin } from "./profile-category.js";
import { uwillProfileWrapperMixin } from "./profile-wrapper.js";

export default {
  name: "ProfileContact",
  mixins: [uwillProfileCategoryMixin, uwillProfileWrapperMixin],
  props: [
    "contact1NameValidated",
    "contact1TelephoneValidated",
    "contact2NameValidated",
    "contact2TelephoneValidated",
    "userTelephone"
  ],
  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    contact1Name: "",
    contact1Telephone: "",
    contact2Name: "",
    contact2Telephone: "",
    telephone: "",
    state: null,
    country: null
  }),
  computed: {
    contact2NameErrorMessages() {
      return this.requiredErrors("contact2Name");
    },
    contact1NameErrorMessages() {
      return this.requiredErrors("contact1Name");
    },
    contact2TelephoneErrorMessages() {
      return this.phoneRequiredErrors(
        "contact2Telephone",
        true,
        this.duplicatePhoneErrors(this.contact2Telephone)
      );
    },
    contact1TelephoneErrorMessages() {
      return this.phoneRequiredErrors(
        "contact1Telephone",
        true,
        this.duplicatePhoneErrors(this.contact1Telephone)
      );
    },
    // Rule for validating the phone number
    phoneNumberRule() {
      return value => {
        // Basic regex for phone number validation
        const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-.(\s]*(\d{1,4})[-.)\s]*(\d{3,5})[-. ]*(\d{3,6})$/;
        return phoneRegex.test(value) || 'Please enter a valid phone number';
      };
    }
  },
  watch: {},
  mounted: function() {
    this.contact1Name = this.contact1NameValidated;
    this.contact1Telephone = this.contact1TelephoneValidated;
    this.contact2Name = this.contact2NameValidated;
    this.contact2Telephone = this.contact2TelephoneValidated;
    this.telephone = this.userTelephone;
  },
  methods: {
    // For some reason, updating a single field causes UI to send null for other fields
    // Not sure why, but touching all fields fixes this for now
    // Also, if statements prevent all the errors showing at once during registration
    touchAllContactFields(field) {
      if (field === "contact1Name" || this.contact1Name !== "") {
        this.v$.contact1Name.$touch();
      }
      if (field === "contact1Telephone" || this.contact1Telephone !== "") {
        this.v$.contact1Telephone.$touch();
      }

      if (field === "contact2Name" || this.contact2Name !== "") {
        this.v$.contact2Name.$touch();
      }
      if (field === "contact2Telephone" || this.contact2Telephone !== "") {
        this.v$.contact2Telephone.$touch();
      }
    }
  }
};
</script>
