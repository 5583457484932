"use strict";

import { createApp } from "vue";
import axios from "axios";

// Full config: https://github.com/axios/axios#request-config
if (!process.env.VUE_APP_TESTCAFE_MODE) {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
}
axios.defaults.headers.common["x-api-key"] = process.env.VUE_APP_API_KEY;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

// TODO: There's likely a better way of initializing this (i.e., using main.js createApp), but it works for now
const app = createApp();
const _axios = axios.create(config);
app.config.globalProperties.$http = axios;

axios.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("uwillToken");
    if (token) {
      config.headers.Authorization = "Token token=" + token;
    }
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    if (response.status === 401 || response.status == 403) {
      alert(response.status);
    } else {
      return response;
    }
  },
  function(error) {
    // Do something with response error
    alert(error);
    return Promise.reject(error);
  }
);

const AxiosPlugin = {
  install(app, options) {
    if (!options) {
      options = {};
    }
  }
};

export default AxiosPlugin;
